export const SearchIcon=()=>{
    return(
       
<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" version="1.1">
 <g transform="translate(0 -1028.4)">
  <rect transform="rotate(-45)" height="10" width="4" y="747.57" x="-729.04" fill="#95a5a6"/>
  <rect transform="rotate(-45)" height="3" width="4" y="747.57" x="-729.04" fill="#7f8c8d"/>
  <path d="m3.201 1031.4c-3.5147 3.5-3.5147 9.2 0 12.7 3.5148 3.5 9.213 3.5 12.728 0s3.515-9.2 0-12.7-9.2133-3.5-12.728 0zm2.1213 2.1c2.3432-2.3 6.1417-2.3 8.4857 0 2.343 2.4 2.343 6.2 0 8.5-2.344 2.3-6.1425 2.3-8.4857 0-2.3431-2.3-2.3431-6.1 0-8.5z" fill="#bdc3c7"/>
  <path d="m16 9.5a6.5 6.5 0 1 1 -13 0 6.5 6.5 0 1 1 13 0z" transform="translate(0 1028.4)" fill="#ecf0f1"/>
 </g>
</svg>
    )
}