export const ShareIconLarge=()=>{
    return(
      
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
	 width="60px" height="60px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" >
<circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="51" cy="13" r="12"/>
<circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="11" cy="42" r="10"/>
<circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="48" cy="55" r="8"/>
<line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="40" y1="54" x2="20" y2="46"/>
<line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="19" y1="35" x2="41" y2="21"/>
</svg>
    )
}