import React from "react";
import { Logo } from "../assets";

const FooterLink = ({ to, text, externalLink = false }) => {
  return externalLink ? (
    <a
      href={to}
      target="_blank"
      rel="noreferrer"
      className="text-gray-400 text-12px font-normal hover:font-medium">
      {text}
    </a>
  ) : (
    <a href={to} className="text-gray-400 text-12px font-normal hover:font-medium">
      {text}
    </a>
  );
};

const platformArray = [
  { text: "Product", to: "#" },
  { text: "Pricing", to: "/price" },
  { text: "Referral", to: "https://platform.realiti.io/refer", externalLink: true },
];

const resourcesArray = [
  { text: "Blog", to: "/blog" },
  { text: "Community", to: "https://platform.realiti.io/community" },
  { text: "Help & Support", to: "https://help.realiti.io/", externalLink: true },
  // { text: "Feature Requests", to: "#" },
  // { text: "Customer Support", to: "#" },



];

// const companyArray = [
//   { text: "About us", to: "#" },
//   { text: "Press", to: "#" },
//   { text: "Contact us", to: "#" },
//   { text: "Dispute Resolution", to: "#", externalLink: true },
//   { text: "Careers", to: "#" },
// ];

const usefulArray = [
  { text: "Privacy Policy", to: "/privacy-policy" },
  { text: "Terms of Use", to: "/terms-of-use" },
  // { text: "Compliances", to: "#" },
  { text: "Refund & Returns Policy", to: "/refund-policy", },
  // { text: "Takedown Policy", to: "#" },
  // { text: "Code of Conduct", to: "#" },

];

const Footer = () => {
  let getYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
  };

  // const callBackToTop = () => {
  //   navigate('#header')
  // }

  return (
    <>
      {/* Footer Section */}
      <div className="relative  w-full py-8 " style={{ zIndex: 900 }}>
        <div className="bg-secondary m-8 mb-10 rounded-30px">
          <div className="w-4/5 px-4  mx-auto rounded-xl py-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-x-4 gap-y-5  ">
            <div className="flex flex-col space-y-5">
              <div className="cursor-pointer flex flex-row space-x-3 p-2">
                <a
                  href="/"
                  className="group inline-flex p-2 bg-white rounded-md flex-row items-center space-x-4 cursor-pointer">
                  <img
                    className="w-[80px] xl:w-[140px]"
                    src={Logo}
                    alt="Logo"
                  />
                </a>{" "}
              </div>
              <div className="flex flex-row items-center space-x-4">
                {/* Facebook Icon */}
                <a href="/" target="_blank" rel="noreferrer" className="p-2">
                  <svg
                    className="cursor-pointer"
                    width="18"
                    height="16"
                    viewBox="0 0 8 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.4893 2.63607H7.93865V0.111793C7.6886 0.0773953 6.82865 0 5.82714 0C3.73747 0 2.30598 1.3144 2.30598 3.73019V5.95349H0V8.77544H2.30598V15.876H5.13323V8.7761H7.34594L7.6972 5.95415H5.13257V4.01C5.13323 3.19438 5.35285 2.63607 6.4893 2.63607Z"
                      fill="white"
                    />
                  </svg>
                </a>

                {/* Twitter Icon */}
                <a href="/" target="_blank" rel="noreferrer" className="p-2">
                  <svg
                    className="cursor-pointer"
                    width="18"
                    height="16"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.494 1.881C19.7668 2.2 18.9919 2.41144 18.184 2.51411C19.0151 2.01789 19.6495 1.23811 19.9477 0.298222C19.1728 0.760222 18.3173 1.08656 17.4055 1.26867C16.6697 0.485222 15.621 0 14.477 0C12.2575 0 10.4706 1.80156 10.4706 4.01011C10.4706 4.32789 10.4975 4.63344 10.5635 4.92433C7.23048 4.76178 4.28125 3.16433 2.30003 0.730889C1.95414 1.331 1.75125 2.01789 1.75125 2.75733C1.75125 4.14578 2.46625 5.37656 3.53203 6.08911C2.88792 6.07689 2.25603 5.88989 1.7207 5.59533C1.7207 5.60756 1.7207 5.62344 1.7207 5.63933C1.7207 7.58756 3.11037 9.20578 4.9327 9.57856C4.60637 9.66778 4.2507 9.71056 3.88159 9.71056C3.62492 9.71056 3.36581 9.69589 3.12259 9.64211C3.64203 11.2298 5.11603 12.397 6.8687 12.4349C5.5047 13.5019 3.77281 14.1448 1.89792 14.1448C1.56914 14.1448 1.25381 14.1301 0.938477 14.0898C2.71437 15.235 4.81903 15.8889 7.0887 15.8889C14.466 15.8889 18.4994 9.77778 18.4994 4.48067C18.4994 4.30344 18.4933 4.13233 18.4847 3.96244C19.2804 3.39778 19.9489 2.69256 20.494 1.881Z"
                      fill="white"
                    />
                  </svg>
                </a>

                {/* Insta Icon */}
                <a href="/" target="_blank" rel="noreferrer" className="p-2">
                  <svg
                    className="cursor-pointer"
                    width="18"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.49414 0H11.4941C14.2551 0 16.4941 2.239 16.4941 5V11C16.4941 13.761 14.2551 16 11.4941 16H5.49414C2.73314 16 0.494141 13.761 0.494141 11V5C0.494141 2.239 2.73314 0 5.49414 0ZM11.4941 14.5C13.4241 14.5 14.9941 12.93 14.9941 11V5C14.9941 3.07 13.4241 1.5 11.4941 1.5H5.49414C3.56414 1.5 1.99414 3.07 1.99414 5V11C1.99414 12.93 3.56414 14.5 5.49414 14.5H11.4941Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.49414 8C4.49414 5.791 6.28514 4 8.49414 4C10.7031 4 12.4941 5.791 12.4941 8C12.4941 10.209 10.7031 12 8.49414 12C6.28514 12 4.49414 10.209 4.49414 8ZM5.99414 8C5.99414 9.378 7.11614 10.5 8.49414 10.5C9.87214 10.5 10.9941 9.378 10.9941 8C10.9941 6.621 9.87214 5.5 8.49414 5.5C7.11614 5.5 5.99414 6.621 5.99414 8Z"
                      fill="white"
                    />
                    <circle cx="12.7942" cy="3.69999" r="0.533" fill="white" />
                  </svg>
                </a>
              </div>


            </div>

            <div className="w-full md:w-4/5 xl:w-3/4 mx-auto space-y-5">
              <h3 className="text-white text-[12px] font-medium">PLATFORM</h3>

              <div className="w-full list-none text-gray-400 text-xs space-y-3">
                {platformArray.map((item, index) => {
                  return (
                    <div key={index}>
                      <FooterLink
                        to={item.to}
                        text={item.text}
                        externalLink={item.externalLink && item.externalLink}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-full md:w-4/5 xl:w-3/4 mx-auto space-y-5">
              <h3 className="text-white text-[12px] font-medium uppercase">
                Resources
              </h3>

              <div className="w-full list-none text-gray-400 text-xs space-y-3">
                {resourcesArray.map((item, index) => {
                  return (
                    <div key={index}>
                      <FooterLink to={item.to} text={item.text} />
                    </div>
                  );
                })}
              </div>
            </div>



            <div className="w-full md:w-4/5 xl:w-3/4 mx-auto space-y-5">
              <h3 className="text-white text-[12px] font-medium uppercase">
                Useful Information
              </h3>

              <div className="w-full list-none text-gray-400 text-xs space-y-3">
                {usefulArray.map((item, index) => {
                  return (
                    <div key={index}>
                      <FooterLink to={item.to} text={item.text} />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-full md:w-4/5 xl:w-4/5 mx-auto space-y-5">

              <div className="fle flex-col space-y-3">
                <p className="text-gray-300 text-xs font-normal space-x-2">
                  <span className="font-bold text-white">Address:</span> No.127, 2nd Floor, West Sambandam Road, R.S. Puram, Coimbatore, Tamil Nadu 641002
                </p>
                <p className="text-gray-300 text-xs font-normal space-x-2">
                  <span className="font-bold text-white">Phone:</span> +91 97912 48260
                </p>
                <p className="text-gray-300 text-xs space-x-2  font-normal">
                  <span className="font-bold text-white"> Email: </span>
                  support@realiti.lo
                </p>
              </div>
            </div>

          </div>
          <div className="py-6 text-center ">
            <p className="text-gray-200 text-opacity-40 text-xs md:text-base  lg:text-sm font-normal">
              Copyright &copy; {getYear()}
              <span
                className="ml-1 cursor-pointer"
              // onClick={() => navigate("/")}
              >
                Realiti.io Private Limited. <br className="block lg:hidden" /> All rights reserved.{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
