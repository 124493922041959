import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import ForBrokers from "./Pages/ForBrokers";
import './App.css';
import HowItsWork from "./Pages/How-its-works";
import ForBuilders from "./Pages/ForBuilders";
import Blogs from "./Pages/Blogs";
import GettingStarted from "./Pages/Blogs/getting-started";
import AboutUs from "./Pages/About";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse";
import RefundPolicy from "./Pages/RefundPolicy";
import ContactUs from "./Pages/ContactUs";
import Price1 from "./Pages/Price-1";
import Price from "./Pages/Price";
import OnlineStorePage from "./Pages/OnlineStore";

function App() {
  return (
    <>
            <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/online-store' element={<OnlineStorePage/>} />
            <Route path='/for-brokers' element={<ForBrokers/>} />
            <Route path='/how-its-works' element={<HowItsWork/>} />
            <Route path='/for-builders' element={<ForBuilders/>} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/price' element={<Price />} />
            <Route path='/price1' element={<Price1 />} />
            <Route path='/blogs' element={<Blogs />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-of-use' element={<TermsOfUse />} />
            <Route path='/refund-policy' element={<RefundPolicy />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/blogs/getting-started' element={<GettingStarted />} />

          </Routes>
    </>
  );
}


export default App;
