'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import { staggerContainer } from '../utils/motion';
import { Heading, SubHeading } from '../components/CustomTexts';

const OurPeopleCulture = () => (
    <section className={`${styles.paddings} relative z-10 flex items-center justify-center `}>
        <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidth} w-full flex  flex-col items-start justify-start`}
        >
            <SubHeading
                title={<>Our People & Culture</>}
                textStyles="text-left"
            />
            <Heading title={<> We Cant Choose Where We Come From But <br /> We Can Choose Where We Want To Go

            </>} textStyles="text-left mb-4" />

            <div className='flex flex-col space-y-6'>
                <p className=" w-full lg:w-3/4 text-base lg:text-lg text-font_primary font-Poppins ">We bring in self-motivated passionate people and proudly call them Realiti.we come to work as ourselves. Appearances and backgrounds aren’t important to us but progressive ideas and going the right way are.

                </p>
                <p className="w-full lg:w-3/4 text-base lg:text-lg text-font_primary font-Poppins ">We passionately strive to make the real estate industry more reflective and inclusive of the society that we serve. Learning happens everyday and we believe in being enthusiastic each day

                </p>
            </div>


        </motion.div>
    </section>
);

export default OurPeopleCulture;
