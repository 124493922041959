import React, { useEffect, useState } from 'react'
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import {

  staggerContainer,
} from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar, } from "../components";
import Button from "../components/common/Button";
import { BgGradient } from "../assets";
import { Enjoy, PricingList } from '../sections';
import Categories from '../sections/Categories';
import Sales from '../sections/Sales';


const Price = () => {

  const [showFloatHeader, setShowFloatHeader] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)



  useEffect(() => {
    document.getElementById('layout').addEventListener('scroll', () => {
      document.getElementById('layout').scrollTop > 50 ? setShowFloatHeader(true) : setShowFloatHeader(false);
    })
  })



  return (
    <Layout id="layout" showFloatHeader={showFloatHeader} showSideBar={showSideBar} setShowSideBar={setShowSideBar}>

      <div
        style={{ backgroundImage: `url(${BgGradient})` }}
        className="bg-no-repeat bg-cover">

        <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

        <section className={`${styles.yPaddings} w-full bg-cover `}>
          <motion.div
            variants={staggerContainer}
            initial=""
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidth} relative w-4/5  mx-auto pb-6 flex flex-col-reverse xl:flex-row`}>

            <div className="w-full px-10 lg:px-14 mt-8 xl:mt-14 mx-auto flex xl:items-center xl:justify-center ">
              <div className="flex flex-col items-center space-y-6">
                <h1 className="text-[28px] lg:text-[45px] text-center font-Poppins font-medium text-black ">
                  All in one platform to help you emerge <br /> a kickass Realiti-preneur
                </h1>

                <div className="flex flex-row space-x-4">
                  <Button text="GET STARTED" to="https://platform.realiti.io/" />
                </div>
              </div>
            </div>

          </motion.div>
        </section>

      </div>

      <div className="relative px-2">
        <PricingList />
        <Categories />
        <Enjoy />
        <Sales />
      </div>
      <Footer />
    </Layout>
  );
};

export default Price;
