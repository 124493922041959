import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "./NavList";

const SubNavLink = () => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  return (
    <>
      {links.map((link) => (
        <div>
          <div className="px-2 text-left md:cursor-pointer group">
            <h1
              className="py-3 flex text-base text-gray-900 font-normal font-Poppins  hover:text-primary capitalize cursor-pointer group"
              onClick={() => {
                heading !== link.name ? setHeading(link.name) : setHeading("");
                setSubHeading("");
              }}
            >
              {link.name}
              <span className="text-xl  md:ml-2  md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.44333 8.51584L11 12.0725L14.5567 8.51584C14.9142 8.15834 15.4917 8.15834 15.8492 8.51584C16.2067 8.87334 16.2067 9.45084 15.8492 9.80834L11.6417 14.0158C11.2842 14.3733 10.7067 14.3733 10.3492 14.0158L6.14166 9.80834C5.78416 9.45084 5.78416 8.87334 6.14166 8.51584C6.49916 8.1675 7.08583 8.15834 7.44333 8.51584Z" fill="currentColor" />
                </svg>
              </span>
            </h1>
            {link.submenu && (
              <div>
                <div className={` ${subHeading ? '' : 'absolute'} top-12 w-36 z-10 hidden group-hover:md:block hover:md:block `}>
                  <div className="py-3">
                    <div
                      className="w-4 h-4 left-3 absolute 
                    mt-1 bg-white rotate-45"
                    ></div>
                  </div>
                  <div className="bg-white p-4 grid grid-cols-3 gap-6">
                    {link.sublinks.map((mysublinks) => (
                      <ul className="list-none">
                        {mysublinks.sublink.map((slink) => (
                          <li className="text-base text-gray-900 font-normal font-Poppins  hover:text-primary capitalize cursor-pointer my-2">
                            {slink.externalLink ? (
                              <a
                                href={slink.link}
                                target="_blank"
                                rel="noreferrer"
                                className="hover:text-primary"
                              >
                                {slink.name}
                              </a>
                            ) :
                              <Link
                                to={slink.link}
                                className="hover:text-primary"
                              >
                                {slink.name}
                              </Link>
                            }
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            )}


          </div>
        </div>
      ))}
      {links.map((link) => (
        <div>
          <div className="px-2 text-left md:cursor-pointer group">
            <h1
              className="py-3 flex text-base text-gray-900 font-normal font-Poppins  hover:text-primary capitalize cursor-pointer group"
              onClick={() => {
                heading !== link.productname ? setHeading(link.productname) : setHeading("");
                setSubHeading("");
              }}
            >
              {link.thirdList}
              <span className="text-xl  md:ml-2  md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.44333 8.51584L11 12.0725L14.5567 8.51584C14.9142 8.15834 15.4917 8.15834 15.8492 8.51584C16.2067 8.87334 16.2067 9.45084 15.8492 9.80834L11.6417 14.0158C11.2842 14.3733 10.7067 14.3733 10.3492 14.0158L6.14166 9.80834C5.78416 9.45084 5.78416 8.87334 6.14166 8.51584C6.49916 8.1675 7.08583 8.15834 7.44333 8.51584Z" fill="currentColor" />
                </svg>
              </span>
            </h1>
            {link.submenu && (
              <div>
                <div className={` ${subHeading ? '' : 'absolute'} top-12 w-36 z-10 hidden group-hover:md:block hover:md:block `}>
                  <div className="py-3">
                    <div
                      className="w-4 h-4 left-3 absolute 
                    mt-1 bg-white rotate-45"
                    ></div>
                  </div>
                  <div className="bg-white" >
                    {link.productarray.map((mysublinks) => (
                      <ul className="list-none">
                        {mysublinks.sublink.map((slink) => (
                          <li className="" style={{ width: '100%', padding: "9px 0px 0px 10px" }}>
                            <div className="" style={{ width: '100%' }}>
                              {slink.externalLink ? (
                                <a
                                  href={slink.link}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="hover:text-primary"
                                >
                                  {slink.name}
                                </a>
                              ) :
                                <Link
                                  to={slink.link}
                                  className="hover:text-primary"
                                >
                                  {slink.name}
                                </Link>
                              }
                            </div>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            )}


          </div>
        </div>
      ))}

      {links.map((link) => (
        <div>
          <div className="px-2 text-left md:cursor-pointer group">
            <h1
              className="py-3 flex text-base text-gray-900 font-normal font-Poppins  hover:text-primary capitalize cursor-pointer group"
              onClick={() => {
                heading !== link.resourcename ? setHeading(link.resourcename) : setHeading("");
                setSubHeading("");
              }}
            >
              {link.resourcename}
              <span className="text-xl  md:ml-2  md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.44333 8.51584L11 12.0725L14.5567 8.51584C14.9142 8.15834 15.4917 8.15834 15.8492 8.51584C16.2067 8.87334 16.2067 9.45084 15.8492 9.80834L11.6417 14.0158C11.2842 14.3733 10.7067 14.3733 10.3492 14.0158L6.14166 9.80834C5.78416 9.45084 5.78416 8.87334 6.14166 8.51584C6.49916 8.1675 7.08583 8.15834 7.44333 8.51584Z" fill="currentColor" />
                </svg>
              </span>
            </h1>
            {link.submenu && (
              <div>
                <div className={` ${subHeading ? '' : 'absolute'} top-12 w-36 z-10 hidden group-hover:md:block hover:md:block `}>
                  <div className="py-3">
                    <div
                      className="w-4 h-4 left-3 absolute 
                    mt-1 bg-white rotate-45"
                    ></div>
                  </div>
                  <div className="bg-white" >
                    {link.resourcesarray.map((mysublinks) => (
                      <ul className="list-none">
                        {mysublinks.sublink.map((slink) => (
                          <li className="" style={{ width: '100%', padding: "9px 0px 0px 10px" }}>
                            <div className="" style={{ width: '100%' }}>
                              {slink.externalLink ? (
                                <a
                                  href={slink.link}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="hover:text-primary"
                                >
                                  {slink.name}
                                </a>
                              ) :
                                <Link
                                  to={slink.link}
                                  className="hover:text-primary"
                                >
                                  {slink.name}
                                </Link>
                              }
                            </div>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            )}


          </div>
        </div>
      ))}
    </>
  );
};

export default SubNavLink;