export const GameIcon=()=>{
    return(
        
<svg width="24px" height="24px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">

<defs>


</defs>

<g id="main">

<path class="cls-1" fill="#194568" d="M32,28a1,1,0,0,1-1-1V19a3,3,0,0,1,3-3H49a2,2,0,0,0,0-4H14a3,3,0,0,1-3-3V7a1,1,0,0,1,2,0V9a1,1,0,0,0,1,1H49a4,4,0,0,1,0,8H34a1,1,0,0,0-1,1v8A1,1,0,0,1,32,28Z"/>

<path class="cls-2" fill="#a5a8c6" d="M51,24a8.81,8.81,0,0,0-3.07.56A26.11,26.11,0,0,1,39,26H25.05a25.86,25.86,0,0,1-9-1.45A9,9,0,0,0,13,24C6.38,24,1,31.63,1,41S6.38,58,13,58c3.65,0,7.06-2.29,9.34-6.3A11.23,11.23,0,0,1,32,46h.21a10.58,10.58,0,0,1,9.28,5.47C43.81,55.62,47.26,58,51,58c6.62,0,12-7.63,12-17S57.62,24,51,24Z"/>

<path class="cls-3" fill="#eff3fe" d="M19,39H14V34a1,1,0,0,0-2,0v5H7a1,1,0,0,0,0,2h5v5a1,1,0,0,0,2,0V41h5a1,1,0,0,0,0-2Z"/>

<circle class="cls-3" fill="#eff3fe" cx="51" cy="40" r="7"/>

<path class="cls-3" fill="#eff3fe" d="M30,38H26a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z"/>

<path class="cls-3" fill="#eff3fe" d="M38,38H34a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z"/>

</g>

</svg>
    )
}