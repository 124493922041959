"use client";

import { motion } from "framer-motion";

import styles from "../../styles";
import { staggerContainer, fadeIn } from "../../utils/motion";
import { GettingStart} from "../../assets";
import {  SubHeading } from "../../components/CustomTexts";
import { Link } from "react-router-dom";

const FirstBlog = () => (
  <section
    className={`p-3 relative z-10 bg-white border-0 lg:border shadow-none lg:shadow-sm cursor-pointer rounded-sm`}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto w-full flex lg:flex-row flex-col space-x-5`}>
      <motion.div
        variants={fadeIn("right", "tween", 0.2, 1)}
        className={`flex w-full lg:w-2/5    ${styles.flexCenter}`}>
        <img
          src={GettingStart}
          alt="img"
          className="w-[100%] h-[100%] object-contain"
        />
      </motion.div>
      <motion.div
        variants={fadeIn("left", "tween", 0.2, 1)}
        className="flex w-full lg:w-3/5  justify-center flex-col">
        <div className="flex flex-row space-x-3 text-gray-500 ">
          <h1 className="text-xs text-center ">Category</h1>
          <div className="text-xs text-center">
            . 1 Hour Ago
          </div>
        </div>
        <div className="mt-2 flex flex-row space-x-3">
          <h1 className="font-bold text-base">Getting Started</h1>
          <div className="bg-[#FFF0DC] text-[#F7991E] text-xs text-center px-3 flex items-center justify-center rounded-full">
            Latest
          </div>
        </div>
        <div className="mt-[2px] flex flex-col">
          <SubHeading
            title={
              <>
                Not long ago I decided to improve the loading times of my
                website. It already loads pretty fast, but I knew there was
                still room for improvement and one of them was CSS loading. I
                will walk you through the process and show you how you can
                improve your load times as well.
              </>
            }
            textStyles="text-sm"
          />
          <SubHeading
            title={
              <>
                To see how CSS affects the load time of a webpage we first have
                to know how the browser converts an HTML document into a
                functional webpage...
              </>
            }
          />
        </div>
        <div className="mt-6 flex flex-row space-x-4 px-2">
        <Link to="/blogs/getting-started" className="text-[#1473E6] flex  flex-row text-base font-Poppins items-center justify-center">
            Read Full{" "}
            <svg
              width="20"
              height="20"
              className="ml-2"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2_2366)">
                <path
                  d="M4.16675 10H15.8334"
                  stroke="#1473E6"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.5 13.3333L15.8333 10"
                  stroke="#1473E6"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.5 6.66675L15.8333 10.0001"
                  stroke="#1473E6"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_2366">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
      </motion.div>
    </motion.div>
  </section>
);

export default FirstBlog;
