import React, { useEffect, useState } from 'react'
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import {
  
  staggerContainer,
} from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar } from "../components";
import Button from "../components/common/Button";
import { BgGradient,  } from "../assets";
import LeadingBuilders from '../sections/LeadingBuilders';
import HowWeWork from '../sections/HowWeWork';
import Confidently from '../sections/Confidently';
import Curious from '../sections/Curious';
const ForBuilders = () => {
  
    const [showFloatHeader, setShowFloatHeader] = useState(false)
    const [showSideBar, setShowSideBar] = useState(false)

   

  useEffect(()=> {
      document.getElementById('layout').addEventListener('scroll', () => {
          document.getElementById('layout').scrollTop > 50 ? setShowFloatHeader(true) : setShowFloatHeader(false);
      })
  })


  

  
  return (
    <Layout id="layout" showFloatHeader={showFloatHeader} showSideBar={showSideBar} setShowSideBar={setShowSideBar}>

      <div
        style={{ backgroundImage: `url(${BgGradient})` }}
        className="bg-no-repeat bg-cover">

        <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

        <section className={`${styles.yPaddings} w-full bg-cover `}>
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidth} relative w-4/5  mx-auto pb-6 flex flex-col-reverse xl:flex-row`}>
           
            <div className="w-full px-10 lg:px-14 mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
              <div className="flex flex-col items-start space-y-6">
                <h1 className="tex-sm bg-[#FFF0DC] text-primary px-3 py-1 rounded-full lg:text-base font-Poppins font-medium tracking-tight">
                Why Realiti
                </h1>
                <h1 className="text-[30px] lg:text-[65px] font-Poppins tracking-normal text-black xl:leading-tight">
                Identify channel partners to <br/> amplify sales
                </h1>
                <p className="w-4/5 text-sm md:text-base font-Poppins tracking-normal text-font_primary xl:leading-snug">
                The problem is finding high-quality projects you care about and dealing with all the administration and fees that come with it. Let us help you get back to doing more of what you love.                </p>
                <div className="flex flex-row space-x-4">
                  <Button text="Join our Partner Network" to="/" />
                </div>
              </div>
            </div>
           
          </motion.div>
        </section>
     
      </div>

      <div className="relative">
        <LeadingBuilders/>
        <HowWeWork/>
        <Confidently/>
        <Curious/>
      </div>
      <Footer />
    </Layout>
  );
};

export default ForBuilders;
