export const links = [
    {
      name: "Company",
      secondList:"Resource",
      resourcename: "Resource",
      thirdList:'Products',
      productname:'Products',

      submenu: true,
      sublinks: [
        {
          sublink: [
            // { name: "Community", link: "/" },

            { name: "About", link: "/about" },
            { name: "Partner", link: "https://platform.realiti.io/refer", externalLink: true },
            { name: "Careers", link: "https://www.realiti.io/people ", externalLink: true },
            { name: "Contact", link: "https://platform.realiti.io/contact ", externalLink: true },
            // { name: "Blog", link: "/blogs" },
          ],
        },   
      ],
      resourcesarray: [
        {
          sublink: [
           
            { name: "Documentation", link: "https://help.realiti.io" , externalLink: true },
            { name: "Help & Support", link: "https://help.realiti.io", externalLink: true },
            { name: "Feature Requests", link: "https://help.realiti.io", externalLink: true },
            { name: "Blog", link: "https://realiti.org/blog", externalLink: true },
            { name: "Webinars", link: "https://realiti.org/webinars", externalLink: true },

          ],
        },       
      ],
      productarray: [
        {
          sublink: [
           
            { name: "Online Store", link: "/online-store" , externalLink: true },
            { name: "MLS", link: "https://help.realiti.io", externalLink: true },
            { name: "IDX", link: "https://help.realiti.io", externalLink: true },
            { name: "Lead Magnet", link: "https://realiti.org/blog", externalLink: true },
          ],
        },       
      ],
    },
  ];