'use client';

import { motion } from 'framer-motion';
import { textContainer, textVariant2 } from '../utils/motion';

export const TypingText = ({ title, textStyles }) => (
  <motion.p
    variants={textContainer}
    className={`font-medium font-Poppins text-[14px] text-primary ${textStyles}`}
  >
    {Array.from(title).map((letter, index) => (
      <motion.span variants={textVariant2} key={index}>
        {letter === ' ' ? '\u00A0' : letter}
      </motion.span>
    ))}
  </motion.p>
);

export const TitleText = ({ title, textStyles }) => (
  <motion.h2
    variants={textVariant2}
    initial="hidden"
    whileInView="show"
    className={`px-0  mt-[8px] font-Poppins font-bold md:text-[58px] text-[30px] text-black ${textStyles}`}
  >
    {title}
  </motion.h2>
);

export const Heading = ({ title, textStyles }) => (
  <motion.h2
    variants={textVariant2}
    initial="hidden"
    whileInView="show"
    className={`mt-[8px] font-Poppins font-bold md:text-[45px] text-[25px] text-black ${textStyles}`}
  >
    {title}
  </motion.h2>
);


export const SubHeading = ({ title, textStyles, fontBold = "font-normal" }) => (
  <motion.p
    className={`mt-[10px] font-medium font-Poppins text-[16px] text-font_primary ${textStyles} ${fontBold}`}
  >
    {title}
  </motion.p>
);