'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import { staggerContainer, fadeIn } from '../utils/motion';
import Button from '../components/common/Button';

const Sales = () => (
    <section className={`relative z-10 flex items-center justify-center `}>
        <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidth} px-8 pb-4 lg:px-10 w-full lg:w-full flex items-center justify-center lg:flex-row flex-col gap-8 `}
        >
            <motion.div
                //    style={{backgroundImage: `url(${NextBg})`}}  
                variants={fadeIn('right', 'tween', 0.2, 1)}
                className="p-10 flex-1 flex items-center bg-cover bg-center rounded-30px bg-theme_two justify-center flex-col"
            >
                <h1 className="text-base  xl:text-[26px] font-medium text-white text-center leading-8 ">
                    Realiti for Enterprise
                </h1>
                <div className="mt-[8px] w-2/3 flex flex-col space-x-2 space-y-4">
                    <p className='text-sm text-gray-400 text-center'>Give your customers a comprehensive buying experience, digitally!</p>
                    <div className="flex items-center justify-center mb-6">
                        <Button text="Contact Sales" to="" border='rounded-full' width='w-full lg:w-2/5' />
                    </div>
                </div>
            </motion.div>
        </motion.div>
    </section>
);

export default Sales;
