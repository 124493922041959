'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import { staggerContainer, fadeIn } from '../utils/motion';
import Button from '../components/common/Button';
import { PlayIcon, SolutionImg } from '../assets';
import { Heading, SubHeading } from '../components/CustomTexts';
import { solutionList } from '../constants';
import CheckSteps from '../components/CheckSteps';
import PlayButton from '../components/common/PlayButton';

const Solution = () => (
  <section className={`${styles.paddings} relative z-10 bg-white`}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto w-full flex lg:flex-row flex-col-reverse`}
    >

      <motion.div
        variants={fadeIn('left', 'tween', 0.2, 1)}
        className="flex w-full lg:w-3/5 justify-center flex-col"
      >
        <SubHeading title={<> One stop solution for every realtor </>} />
        <Heading title={<>Become a super <span className='text-primary'>broker</span> today!</>} className="text-black" />
        <div className="mt-[10px] flex flex-col gap-[24px]">
          <SubHeading title={<> Find, share, and manage all your properties in one place. </>} />

          {solutionList.map((feature, index) => (
            <CheckSteps
              key={feature}
              text={feature}
            />
          ))}
        </div>
        <div className="mt-6 flex flex-row space-x-4 px-2">
          <Button text="Get Started" to="https://platform.realiti.io/" />
          <div>
            <PlayButton icon={PlayIcon} text={<>
              <span className="text-primary">Watch</span><br /> Intro Video
            </>} to="/" />
          </div>

        </div>
      </motion.div>
      <motion.div
        variants={fadeIn('right', 'tween', 0.2, 1)}
        className={`flex-1 w-full lg:w-2/5    ${styles.flexCenter}`}
      >
        <img
          src={SolutionImg}
          alt="solutiom"
          className="w-[100%] h-[100%] object-contain"
        />
      </motion.div>
    </motion.div>
  </section>
);

export default Solution;
