export const ShareIcon = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6199 13.07C20.2399 13.07 19.9199 12.78 19.8699 12.4C19.6299 10.14 18.4099 8.09001 16.5299 6.79001C16.1899 6.55001 16.1099 6.09001 16.3399 5.75001C16.5799 5.41001 17.0499 5.33001 17.3799 5.56001C19.6199 7.11001 21.0599 9.55001 21.3499 12.24C21.3899 12.65 21.0999 13.02 20.6799 13.07C20.6699 13.07 20.6399 13.07 20.6199 13.07Z"
        fill=""
      />
      <path
        d="M3.48991 13.12C3.45991 13.12 3.43991 13.12 3.40991 13.12C2.99991 13.07 2.69991 12.7 2.73991 12.29C3.00991 9.60001 4.43991 7.17001 6.64991 5.60001C6.98991 5.36001 7.45991 5.44001 7.69991 5.78001C7.93991 6.12001 7.85991 6.59001 7.51991 6.83001C5.65991 8.14001 4.45991 10.19 4.22991 12.45C4.19991 12.83 3.86991 13.12 3.48991 13.12Z"
        fill="#"
      />
      <path
        d="M12.06 22.61C10.58 22.61 9.16997 22.27 7.84997 21.61C7.47997 21.42 7.32997 20.97 7.51997 20.6C7.70997 20.23 8.15997 20.08 8.52997 20.27C10.69 21.36 13.29 21.38 15.47 20.33C15.84 20.15 16.29 20.31 16.47 20.68C16.65 21.05 16.49 21.5 16.12 21.68C14.84 22.3 13.48 22.61 12.06 22.61Z"
        fill="#"
      />
      <path
        d="M12.06 8.44C10.11 8.44 8.53003 6.86 8.53003 4.91C8.53003 2.96 10.11 1.38 12.06 1.38C14.01 1.38 15.59 2.96 15.59 4.91C15.59 6.86 14 8.44 12.06 8.44ZM12.06 2.89C10.94 2.89 10.03 3.8 10.03 4.92C10.03 6.04 10.94 6.95 12.06 6.95C13.18 6.95 14.09 6.04 14.09 4.92C14.09 3.8 13.17 2.89 12.06 2.89Z"
        fill="#"
      />
      <path
        d="M4.83005 20.67C2.88005 20.67 1.30005 19.09 1.30005 17.14C1.30005 15.2 2.88005 13.61 4.83005 13.61C6.78005 13.61 8.36005 15.19 8.36005 17.14C8.36005 19.08 6.78005 20.67 4.83005 20.67ZM4.83005 15.11C3.71005 15.11 2.80005 16.02 2.80005 17.14C2.80005 18.26 3.71005 19.17 4.83005 19.17C5.95005 19.17 6.86005 18.26 6.86005 17.14C6.86005 16.02 5.95005 15.11 4.83005 15.11Z"
        fill="#"
      />
      <path
        d="M19.1699 20.67C17.2199 20.67 15.6399 19.09 15.6399 17.14C15.6399 15.2 17.2199 13.61 19.1699 13.61C21.1199 13.61 22.6999 15.19 22.6999 17.14C22.6899 19.08 21.1099 20.67 19.1699 20.67ZM19.1699 15.11C18.0499 15.11 17.1399 16.02 17.1399 17.14C17.1399 18.26 18.0499 19.17 19.1699 19.17C20.2899 19.17 21.1999 18.26 21.1999 17.14C21.1899 16.02 20.2899 15.11 19.1699 15.11Z"
        fill="#"
      />
    </svg>
  );
};
