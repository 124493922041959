import { ShareIconLarge } from "../../assets/icons/ShareLargeIcon"

export const ScrollCard=()=>{
    return(
        <div>
            <div className="max-w-xl">

            <h6 className="text-lg mb-8 font-normal">Task Mangament</h6>
            <h1 className="text-4xl font-bold mb-4">Integrate customer service with the rest of your company</h1>
            <h5 className="text-lg font-medium">Get more out of Zoho Desk by connecting it with all the other software your team uses.</h5>
            </div>

            <div className="grid grid-cols-2 xl:grid-cols-3 pt-[120px] gap-y-10">
             
            {
                [0,1,2,3,4,5].map((index)=>{
                    return(
                        <div className="max-w-[250px]" key={index}>
                    <ShareIconLarge /> 
                    <h5 className="text-2xl font-medium my-4">Extend</h5>
                    <p className="text-lg">Customize and extend your help desk to suit the needs of your business.</p>
                </div>
                    )
                })
            }
            </div>
        </div>
    )
}