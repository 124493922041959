import React, { useEffect, useState } from 'react'
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import {

    staggerContainer,
} from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar } from "../components";
import { BgGradient, } from "../assets";
import Mission from '../sections/Mission';
import Experienced from '../sections/Experienced';
import OurPeopleCulture from '../sections/OurPeopleCulture';
import Partners from '../sections/Partners';
const AboutUs = () => {

    const [showFloatHeader, setShowFloatHeader] = useState(false)
    const [showSideBar, setShowSideBar] = useState(false)



    useEffect(() => {
        document.getElementById('layout').addEventListener('scroll', () => {
            document.getElementById('layout').scrollTop > 50 ? setShowFloatHeader(true) : setShowFloatHeader(false);
        })
    })





    return (
        <Layout id="layout" showFloatHeader={showFloatHeader} showSideBar={showSideBar} setShowSideBar={setShowSideBar}>

            <div
                style={{ backgroundImage: `url(${BgGradient})` }}
                className="bg-no-repeat bg-cover">

                <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

                <section className={`${styles.yPaddings} w-full bg-cover `}>
                    <motion.div
                        variants={staggerContainer}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.25 }}
                        className={`${styles.innerWidth} relative w-4/5  mx-auto pb-6 flex flex-col `}>

                        <div className="w-full px-10 lg:px-14 mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                            <div className="flex flex-col items-start space-y-6">
                                <h1 className="tex-sm bg-[#FFF0DC] text-primary px-3 py-1 rounded-full lg:text-base font-Poppins font-medium tracking-tight">
                                    About Us
                                </h1>
                                <h1 className="text-[30px] lg:text-[64px] font-Poppins tracking-normal text-black xl:leading-tight">
                                Revolutionizing the PropTech <br />space since 2012
                                </h1>
                                <p className="w-4/5 text-sm md:text-base font-Poppins tracking-normal text-font_primary xl:leading-snug">
                                    Being in the industry for more than a decade now we have witnessed the problems faced by real estate builders and home seekers from the inside. This platform is an all in one solution for effective selling and informed buying. Our vast experience has made it possible to come up with a complete platform that is unlike any other in the home buying and selling marketplace.
                                </p>

                            </div>
                        </div>
                        <Experienced />

                    </motion.div>
                </section>

            </div>

            <div className="relative">
                <OurPeopleCulture />
                <Mission />
                <Partners />
            </div>
            <Footer />
        </Layout>
    );
};

export default AboutUs;
