'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import {  staggerContainer, fadeIn } from '../utils/motion';
import Button from '../components/common/Button';
import { Heading } from '../components/CustomTexts';
import { NextBg } from '../assets';

const Portal = () => (
  <section  className={`${styles.paddings} relative z-10 flex items-center justify-center `}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} px-8 lg:px-10 w-full lg:w-3/5 flex items-center justify-center lg:flex-row flex-col gap-8 `}
    >
      <motion.div
       style={{backgroundImage: `url(${NextBg})`}}  
        variants={fadeIn('right', 'tween', 0.2, 1)}
        className="p-8 flex-1 flex items-center bg-cover bg-center rounded-30px bg-theme_two justify-center flex-col"
      >
        <Heading title={<>You can be the next <br/> 99 acres</>} textStyles="text-white text-center" />
        <div className="mt-[8px] w-2/3 flex flex-col space-x-2 space-y-4">
          <p className='text-sm text-white text-center'>You are just 30 minutes away from launching your real estate portal.</p>
         <div className="flex items-center justify-center">
         <Button text="Get Started" to="https://platform.realiti.io/" bgColor='bg-white' textColor='text-black'  width='w-full lg:w-2/5'/>
         </div>
        </div>
      </motion.div>
    </motion.div>
  </section>
);

export default Portal;
