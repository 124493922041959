import SwiperSlider from './SwiperTestimonial';



const Slides = [
  {
    cite: `
            <cite className="flex flex-col p-3 text-white"><span className="elementor-testimonial__name ">Dhilip Kumar,</span>
            <span className="elementor-testimonial__company text-white"> Marketing Manager </span>
            </cite>
        `,
    content: `
            <div className="swiper-slide-contents p-3 space-y-5 text-white">
            <div className="elementor-testimonial__text font-bold">Ananya’s Garden</div>
            <div className="elementor-testimonial__text text-gray-300">Our Business and the Realiti tool get along seamlessly. Instead of projecting the entire project, we were able to project a specific flat/villa to our potential customer. It also allows us to adjust the unit’s availability on the go. It provides a lot of extra features, such as the ability to add amenities, photographs, and so on.</div>
            </div>`,
  },
  {
    cite: `
            <cite className="flex flex-col p-3 text-white"><span className="elementor-testimonial__name text-white">Dhilip Kumar,</span>
            <span className="elementor-testimonial__company"> Marketing Manager </span>
            </cite>
        `,
    content: `
            <div className="swiper-slide-contents p-3 space-y-5 text-white">
            <div className="elementor-testimonial__text font-bold">Ananya’s Garden</div>
            <div className="elementor-testimonial__text text-gray-300">Our Business and the Realiti tool get along seamlessly. Instead of projecting the entire project, we were able to project a specific flat/villa to our potential customer. It also allows us to adjust the unit’s availability on the go. It provides a lot of extra features, such as the ability to add amenities, photographs, and so on.</div>
            </div>`,
  },
  {
    cite: `
            <cite className="flex flex-col p-3 text-white"><span className="elementor-testimonial__name">Dhilip Kumar,</span>
            <span className="elementor-testimonial__company"> Marketing Manager </span>
            </cite>
        `,
    content: `
            <div className="swiper-slide-contents p-3 space-y-5 text-white">
            <div className="elementor-testimonial__text font-bold">Ananya’s Garden</div>
            <div className="elementor-testimonial__text text-gray-300">Our Business and the Realiti tool get along seamlessly. Instead of projecting the entire project, we were able to project a specific flat/villa to our potential customer. It also allows us to adjust the unit’s availability on the go. It provides a lot of extra features, such as the ability to add amenities, photographs, and so on.</div>
            </div>`,
  },
]

const SecondTestimonials = () => {
    return (
      <>
      <div className="relative pt-14 lg:pt-10">
       
        <div className="flex flex-row items-center justify-center">
          <div className="hidden xl:block flex-col  w-4/6  ">
            <SwiperSlider
              slidesPerView={1}
              slides={Slides}
              spaceBetween={40}
              slideDeley={5000}
            />
          </div>

          <div className="block xl:hidden pt-3 px-6 w-full">
            <SwiperSlider
              slidesPerView={1}
              slides={Slides}
              spaceBetween={40}
              slideDeley={5000}
            />
          </div>
        </div>
      </div>
      </>
    )}
export default SecondTestimonials
