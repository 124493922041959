"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { fadeIn, staggerContainer } from "../utils/motion";
import { MissionData } from "../constants";
import { Heading, SubHeading } from "../components/CustomTexts";

export const MissionCard = ({
  key,
  id,
  title,
  index,
  paragraph,
  bg,
  text,
  icon,
}) => {
  return (
    <motion.div
      variants={fadeIn("right", "spring", index * 0.5, 0.75)}
      key={key}
      className={`w-full p-4 flex flex-col space-y-3 items-center  justify-center bg-white rounded-10px `}>
      <div className={`flex items-center justify-center`}>
        <img src={icon} alt={title} className="w-32 h-28" />
      </div>
      <div className="px-6 w-full text-center  items-center justify-center">
        <p className="text-base  text-gray-500">{paragraph}</p>
      </div>
    </motion.div>
  );
};

const Mission = () => (
  <section className={`${styles.paddings} relative z-10 bg-[#F2F8FD]`}>
    <Heading
      title={
        <>
          To Better <span className="text-primary">  Real Estate </span> Marketplace And Make           <br className="md:block hidden" />
          It Available And Accessible To All
        </>
      }
      textStyles="text-left"
    />
    <SubHeading
      title={
        <>
          Our strong knowlegde and expertize help us create a
          better marketplace for the builders and the home buyers.
        </>
      }
      textStyles="text-left text-lg mb-6"
    />
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto grid grid-cols-1 lg:grid-cols-3 gap-5`}>
      {MissionData.map((world, index) => (
        <MissionCard key={world.id} {...world} index={index} />
      ))}
    </motion.div>
  </section>
);

export default Mission;
