'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import { client } from '../constants';
import { fadeIn, staggerContainer } from '../utils/motion';

export const Builders = ({ key ,id, url, title, index, paragraph , bg, text , icon}) => {
 
  return (

  
  <motion.div
  variants={fadeIn('left', 'spring', index * 0.5, 1)}
  key={key}
  className={`w-full ${
    index % 2 === 0 ? '':'bg-[#F8F8F8] shadow-md shadow-slate-100 ' }p-4 flex rounded-10px flex-col space-y-2 items-center  justify-center  `}
  >            
 <img src={url} alt={title} className='' />
  </motion.div>
)
}



const LeadingBuilders = () => {

  return (
    <section className={`${styles.paddings}`} >
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col`}
      >
        
         <p className="text-center text-sm md:text-base font-bold font-Poppins tracking-normal text-black xl:leading-snug">
         Get access to over 100+ leading builders in the country!
         </p>
        <div className="mt-[50px] flex lg:flex-row flex-col   gap-5">
          {client.map((logos, index) => (
            <Builders
              key={index}
              {...logos}
              index={index}
            />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default LeadingBuilders;
