"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { EmergeData } from "../constants";
import { staggerContainer } from "../utils/motion";
import { ExploreCard } from "../components";
import { Heading, SubHeading } from "../components/CustomTexts";

const Explore = () => {

  return (
    <section className={`${styles.paddings}`}>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex flex-col`}>
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <Heading
            title={
              <>
                Emerge as a <span className="text-primary">super broker </span>
                <br className="md:block hidden" />
                with everything you need
              </>
            }
            textStyles="text-left"
          />
          <SubHeading
            title={
              <>
                Whether you are a newbie or a full scale
                <br className="md:block hidden" />
                enterprise, we have got you covered.
              </>
            }
            textStyles="text-left text-lg"
          />
        </div>

        <div className="px-2 mt-[50px] flex lg:flex-row flex-col  gap-5">
          {EmergeData.map((world, index) => (
            <ExploreCard
              key={world.id}
              {...world}
              index={index}

            />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Explore;
