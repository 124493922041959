import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { motion } from "framer-motion";
import {  staggerContainer } from "../../utils/motion";
import styles from "../../styles";
import { Footer, Navbar } from "../../components";
import { BgGradient, ConnectingImg, GettingStart } from "../../assets";

import { BlogSublist } from "../../constants";
import { Link } from "react-router-dom";

export const FeaturesTabItem = ({ key, title, slug, content }) => {
  return (
    <>
      <div
        key={key}
        className={`w-full flex flex-col items-start  px-2 cursor-pointer`}>
        <div className="  flex flex-col px-4 py-1">
          <Link
            to={`/${slug}`}
            className={` hover:text-primary text-black
            text-left mt-[6px] font-medium font-Poppins md:text-[16px]  text-[14px] `}>
            {title}
          </Link>
        </div>
      </div>
    </>
  );
};

export const MobileTap = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="py-1.5 bg-white rounded-sm">
      <div
        className={`h-1 bg-primary`}
        style={{ width: `100%`, transition: "width 10s ease-in-out" }}></div>
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className={`px-4 py-5 flex flex-row items-center justify-between `}>
        <p className={`font-medium text-lg text-black`}>
          Technical Documentation
        </p>
        <div
          className={`${
            open ? "text-primary rotate-180" : "text-font_secondary rotate-0"
          } transform transition-all ease-in duration-300`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        {/* <DropDownIcon classNames={`${currentFeature === index && open ? "text-primary rotate-180" : "text-font_secondary rotate-0"} transform transition-all ease-in duration-300`} /> */}
      </div>
      {open ? (
        <div
          className={`transition-all duration-500 ease-in-out overflow-hidden `}>
          <div>{children}</div>
        </div>
      ) : null}
    </div>
  );
};

const GettingStarted = () => {
 
  const [showFloatHeader, setShowFloatHeader] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);

  

  useEffect(() => {
    document.getElementById("layout").addEventListener("scroll", () => {
      document.getElementById("layout").scrollTop > 50
        ? setShowFloatHeader(true)
        : setShowFloatHeader(false);
    });
  });


  return (
    <Layout
      id="layout"
      showFloatHeader={showFloatHeader}
      showSideBar={showSideBar}
      setShowSideBar={setShowSideBar}>
      <div
        style={{ backgroundImage: `url(${BgGradient})` }}
        className="bg-no-repeat ">
        <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

        <section
          className={`${styles.yPaddings} w-full px-10  flex flex-col lg:flex-row`}>
          <div className="w-full lg:w-1/5">
            <div className="hidden w-full shadow-sm bg-white border h-full rounded-md space-x-2 lg:flex flex-col  overflow-x-hidden overflow-y-hidden">
              <p className={`font-medium mt-8 mb-3 px-6 text-base text-black`}>
                Technical Documentation
              </p>
              {BlogSublist.map((feature, index) => {
                return (
                  <div key={index}>
                    <FeaturesTabItem
                      key={index}
                      slug={feature.id}
                      content={feature.content}
                      // icon={feature.icon}
                      title={feature.title}
                    />
                  </div>
                );
              })}
            </div>
            <div className="block lg:hidden">
              <MobileTap>
                {BlogSublist.map((feature, index) => {
                  return (
                    <div key={index}>
                      <FeaturesTabItem
                        key={index}
                        slug={feature.id}
                        content={feature.content}
                        title={feature.title}
                      />
                    </div>
                  );
                })}
              </MobileTap>
            </div>
          </div>
          <div className="relative w-full lg:w-4/5  mx-auto px-8 ">
            <motion.div
              variants={staggerContainer}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.25 }}
              className={`${styles.innerWidth} relative mx-auto pb-10 flex flex-col-reverse xl:flex-row`}>
              <div className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                <div className="flex flex-col items-start space-y-6">
                  <h1 className="text-[30px] lg:text-[50px] font-Poppins tracking-normal text-black xl:leading-tight">
                    My Network Workflow
                  </h1>
                  <p className="w-3/4 text-sm md:text-base font-Poppins tracking-normal text-gray-600 xl:leading-snug">
                    After ‘Home’ and ‘Projects’, ‘My Network’ is the third
                    facility provided by Realiti.io. If you click on this
                    section as shown on the left-hand side column you will see
                    the below screen, where you can add your fellow brokers or
                    builders.
                  </p>
                  <motion.div className={`flex ${styles.flexCenter}`}>
                    <img
                      src={GettingStart}
                      alt="img"
                      className="w-[100%] object-contain"
                    />
                  </motion.div>
                  <p className="w-3/4 text-sm md:text-base font-Poppins tracking-normal text-gray-600 xl:leading-snug">
                    You can search for your friends by typing their name on the
                    search toolbar and pressing the ‘enter’ button.
                  </p>
                  <p className="w-3/4 text-sm md:text-base font-Poppins tracking-normal text-gray-600 xl:leading-snug">
                    Alternatively, you can press the ‘location’ button to look
                    for nearby brokers and builders on the map with the help of
                    location tracking as shown below.
                  </p>
                  <motion.div className={`flex ${styles.flexCenter}`}>
                    <img
                      src={ConnectingImg}
                      alt="img"
                      className="w-[100%] object-contain"
                    />
                  </motion.div>
                  <p className="w-3/4 text-sm md:text-base font-Poppins tracking-normal text-gray-600 xl:leading-snug">
                    You can search for your friends by typing their name on the
                    search toolbar and pressing the ‘enter’ button.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
      </div>

      <div className="relative"></div>
      <Footer />
    </Layout>
  );
};

export default GettingStarted;
