import React from 'react'
// import { useRouter } from 'next/navigation';


const NavLink = ({text, to, classNames=""}) => {
  // const router = useRouter();

  return (
    <>
        <a href={`${to}`} className={`text-base text-gray-900 font-normal font-Poppins  hover:text-primary capitalize cursor-pointer ${classNames}`}>
            {text}
        </a>
    </>
  )
}

export default NavLink