'use client';

import { motion } from 'framer-motion';

import { fadeIn } from '../utils/motion';

const LogoCard = ({ key ,id, url, title, index, paragraph , bg, text , icon}) => {
 
  return (

  
  <motion.div
  variants={fadeIn('up', 'spring', index * 0.5, 1)}
  key={key}
    className={`w-full p-4 flex flex-col space-y-2 items-center  justify-center bg-white shadow-lg shadow-slate-200 rounded-20px`}
  >            
 <img src={url} alt={title} className='' />
  </motion.div>
)
  }

export default LogoCard;
