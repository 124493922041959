"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { ManageData } from "../constants";
import { staggerContainer } from "../utils/motion";
import { ManageCard } from "../components";
import { Heading, SubHeading } from "../components/CustomTexts";

const Manage = () => {

    return (
        <section className={`${styles.paddings}`}>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.25 }}
                className={`${styles.innerWidth} flex flex-col`}>
                <div className="flex flex-col">
                    <SubHeading
                        title={
                            <>
                                An Real estate Online Store to
                            </>
                        }
                        textStyles="text-left text-lg"
                    />
                    <Heading
                        title={
                            <>
                                Manage, Market & Sell Unit Homes
                                {/* <span className="text-primary">super broker </span> */}
                                <br className="md:block hidden" />
                                Faster. Online.
                            </>
                        }
                        textStyles="text-left"
                    />

                </div>

                <div className="px-1 lg:px-5 mt-[50px] flex lg:flex-row flex-col  gap-5 lg:gap-16">
                    {ManageData.map((world, index) => (
                        <ManageCard
                            key={world.id}
                            {...world}
                            index={index}

                        />
                    ))}
                </div>
            </motion.div>
        </section>
    );
};

export default Manage;
