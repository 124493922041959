'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import { staggerContainer, fadeIn } from '../utils/motion';
import { Heading, SubHeading } from '../components/CustomTexts';
import { ContactData } from '../constants';

export const AddressList = ({ title, subtitle, imgUrl }) => (
    <div className={`flex flex-row items-center justify-center mb-10`}>
        <div
            className={`flex items-center justify-center px-2 py-2 rounded-full bg-primary`}
        >
            <img src={imgUrl} alt="icon" className='w-6' />

        </div>
        <div>
            <p className="flex-1 ml-[30px] font-bold font-Poppins text-[22px] text-black leading-[32.4px]">
                {title}
            </p>
            <p className="w-4/5 flex-1 ml-[30px] font-normal text-[px] text-font_primary">
                {subtitle}
            </p>
        </div>

    </div>
);


const Address = () => (
    <section className={`${styles.paddings} relative bg-[#EBF0F9]`}>
        <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidth} mx-auto w-full flex lg:flex-row flex-col`}
        >

            <motion.div
                variants={fadeIn('left', 'tween', 0.2, 1)}
                className="flex w-full lg:w-3/5  justify-center flex-col px-5 lg:px-12  "
            >
                <div className=" flex flex-row space-x-4 px-2 items-center">
                    <svg width="87" height="3" viewBox="0 0 87 3" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.939453 1.55811H86.6713" stroke="#1D2130" strokeWidth="2" />
                    </svg>
                    <p className="flex-1 ml-[4px] font-medium text-[1bpx] text-black">
                        Contact us
                    </p>
                </div>

                <Heading title={<>We'd love to hear <br /> from you </>} className="text-black" />
                <div className="mt-[10px] flex flex-col w-3/4">
                    <SubHeading title={<> Have any question in mind or want to enquire? Please feel free to contact us through the form or the following details. </>} />


                </div>

            </motion.div>
            <motion.div
                variants={fadeIn('right', 'tween', 0.2, 1)}
                className={`w-full lg:w-2/5 mt-4 flex flex-col items-start justify-start `}
            >
                {ContactData.map((item, index) => (
                    <AddressList
                        key={item.id}
                        {...item}
                        index={index}
                    />
                ))}
            </motion.div>
        </motion.div>
    </section>
);

export default Address;
