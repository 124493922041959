'use client';

import { motion } from 'framer-motion';

import styles from '../styles';

import { fadeIn, staggerContainer } from '../utils/motion';
import { WorkIcon1, WorkIcon2, WorkIcon3 } from '../assets';
const ShowCase = () => (
  <section className='pb-6' >
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto flex flex-col`}
    >
      <div className="flex flex-row">
        <motion.div
          variants={fadeIn('right', 'tween', 0.2, 1)}
          className="relative w-1/2 px-4 lg:px-10"
        >
          <img src={WorkIcon1} alt="Header" />
        </motion.div>
        <motion.div
          variants={fadeIn('left', 'tween', 0.2, 1)}
          className="w-4/6 self-end"
        >
          <img src={WorkIcon2} alt="Header" />
        </motion.div>
      </div>
      <motion.div
        variants={fadeIn('right', 'tween', 0.2, 1)}
        className="relative"
      >
        <img src={WorkIcon3} alt="Header" />
      </motion.div>
    </motion.div>
  </section>
);

export default ShowCase;
