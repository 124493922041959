export const MessageIcon = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      fill="#fff"
      width="24"
      height="24px"
      viewBox="0 0 600.619 600.619"
    >
      <g>
        <g>
          <path
            d="M541.979,5.707H58.64c-32.059,0-58.14,26.082-58.14,58.14V424.84c0,31.556,25.271,57.32,56.636,58.121l0.139,53.936
			c0.021,8.118,1.679,15.971,4.928,23.339c3.092,7.013,7.483,13.259,13.048,18.563c10.903,10.391,25.18,16.113,40.197,16.113
			c10.843,0,21.472-3.056,30.737-8.834l165.285-103.098h230.508c32.059,0,58.141-26.082,58.141-58.141V63.846
			C600.119,31.788,574.037,5.707,541.979,5.707z M557.279,424.84c0,8.449-6.852,15.3-15.301,15.3H303.586
			c-2.863,0-5.668,0.803-8.097,2.318L123.512,549.73c-2.582,1.61-5.357,2.343-8.064,2.343c-7.958,0-15.311-6.335-15.333-15.285
			l-0.208-81.386c-0.022-8.435-6.866-15.261-15.3-15.261H58.64c-8.45,0-15.3-6.851-15.3-15.301V63.846c0-8.45,6.85-15.3,15.3-15.3
			h483.338c8.449,0,15.301,6.85,15.301,15.3V424.84z"
          />
          <path
            d="M115.448,595.413c-15.147,0-29.545-5.771-40.542-16.251c-5.614-5.352-10.042-11.65-13.161-18.724
			c-3.277-7.433-4.95-15.353-4.97-23.54l-0.137-53.451c-15.138-0.51-29.304-6.771-39.939-17.663C5.93,454.755,0,440.214,0,424.84
			V63.846c0-32.334,26.306-58.64,58.64-58.64h483.338c32.335,0,58.641,26.306,58.641,58.64V424.84
			c0,32.335-26.306,58.641-58.641,58.641H311.614L146.45,586.502C137.104,592.332,126.383,595.413,115.448,595.413z M58.64,6.207
			C26.857,6.207,1,32.063,1,63.846V424.84c0,15.111,5.829,29.404,16.414,40.244c10.566,10.821,24.677,16.993,39.735,17.377
			l0.486,0.013l0.14,54.422c0.021,8.048,1.664,15.833,4.885,23.138c3.065,6.952,7.417,13.145,12.936,18.403
			c10.81,10.302,24.963,15.976,39.852,15.976c10.748,0,21.285-3.028,30.473-8.758l165.406-103.174h230.651
			c31.783,0,57.641-25.857,57.641-57.641V63.846c0-31.783-25.857-57.64-57.641-57.64H58.64z M115.448,552.573
			c-8.709,0-15.812-7.081-15.833-15.784l-0.208-81.386c-0.021-8.14-6.661-14.762-14.8-14.762H58.64c-8.712,0-15.8-7.088-15.8-15.801
			V63.846c0-8.712,7.088-15.8,15.8-15.8h483.338c8.713,0,15.801,7.088,15.801,15.8V424.84c0,8.712-7.088,15.8-15.801,15.8H303.586
			c-2.772,0-5.481,0.775-7.833,2.242L123.777,550.154C121.24,551.737,118.36,552.573,115.448,552.573z M58.64,49.046
			c-8.161,0-14.8,6.639-14.8,14.8V424.84c0,8.161,6.639,14.801,14.8,14.801h25.966c8.689,0,15.777,7.069,15.8,15.76l0.208,81.386
			c0.021,8.153,6.675,14.786,14.833,14.786c2.725,0,5.421-0.784,7.799-2.267l171.977-107.271c2.51-1.566,5.402-2.395,8.362-2.395
			h238.393c8.161,0,14.801-6.639,14.801-14.8V63.846c0-8.161-6.64-14.8-14.801-14.8H58.64z"
          />
        </g>
        <g>
          <path
            d="M428.564,109.893h-256.51c-34.034,0-61.723,27.689-61.723,61.723c0,34.034,27.688,61.723,61.723,61.723h256.51
			c34.033,0,61.723-27.688,61.723-61.723C490.287,137.582,462.598,109.893,428.564,109.893z M428.564,190.499h-256.51
			c-10.429,0-18.883-8.454-18.883-18.882c0-10.429,8.454-18.883,18.883-18.883h256.51c10.43,0,18.883,8.454,18.883,18.883
			C447.447,182.045,438.992,190.499,428.564,190.499z"
          />
          <path
            d="M428.564,233.838h-256.51c-34.31,0-62.223-27.913-62.223-62.223s27.913-62.223,62.223-62.223h256.51
			c34.31,0,62.223,27.913,62.223,62.223S462.874,233.838,428.564,233.838z M172.054,110.393c-33.758,0-61.223,27.464-61.223,61.223
			s27.464,61.223,61.223,61.223h256.51c33.759,0,61.223-27.464,61.223-61.223s-27.464-61.223-61.223-61.223H172.054z
			 M428.564,190.999h-256.51c-10.688,0-19.383-8.695-19.383-19.382s8.695-19.383,19.383-19.383h256.51
			c10.688,0,19.383,8.695,19.383,19.383S439.252,190.999,428.564,190.999z M172.054,153.234c-10.136,0-18.383,8.247-18.383,18.383
			s8.247,18.382,18.383,18.382h256.51c10.137,0,18.383-8.246,18.383-18.382s-8.246-18.383-18.383-18.383H172.054z"
          />
        </g>
        <g>
          <path
            d="M319.367,255.027H172.054c-34.034,0-61.723,27.688-61.723,61.723s27.688,61.723,61.723,61.723h147.313
			c34.033,0,61.723-27.688,61.723-61.723C381.09,282.715,353.4,255.027,319.367,255.027z M319.367,335.632H172.054
			c-10.429,0-18.883-8.454-18.883-18.883c0-10.43,8.454-18.883,18.883-18.883h147.313c10.43,0,18.883,8.454,18.883,18.883
			S329.795,335.632,319.367,335.632z"
          />
          <path
            d="M319.367,378.972H172.054c-34.31,0-62.223-27.913-62.223-62.223s27.913-62.223,62.223-62.223h147.313
			c34.31,0,62.223,27.913,62.223,62.223S353.677,378.972,319.367,378.972z M172.054,255.527c-33.758,0-61.223,27.464-61.223,61.223
			c0,33.758,27.464,61.223,61.223,61.223h147.313c33.758,0,61.223-27.465,61.223-61.223c0-33.758-27.465-61.223-61.223-61.223
			H172.054z M319.367,336.132H172.054c-10.688,0-19.383-8.695-19.383-19.383s8.695-19.383,19.383-19.383h147.313
			c10.688,0,19.383,8.695,19.383,19.383S330.055,336.132,319.367,336.132z M172.054,298.367c-10.136,0-18.383,8.246-18.383,18.383
			s8.247,18.383,18.383,18.383h147.313c10.137,0,18.383-8.246,18.383-18.383s-8.246-18.383-18.383-18.383H172.054z"
          />
        </g>
      </g>
    </svg>
  );
};
