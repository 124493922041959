import { useEffect, useRef, useState } from "react";

import { getDimensions, useIsBrowser, scrollTo } from '../../utils/helper';
import { ScrollCard } from "./ScrollCard";


export default function ScrollSpy() {
  const [visibleSection, setVisibleSection] = useState();

  const isBrowser = useIsBrowser();

  const mainRef = useRef(null)
  const section1 = useRef(null)
  const section2 = useRef(null)
  const section3 = useRef(null)
  const section4 = useRef(null)
  const section5 = useRef(null)

  const [sectionRefs] = useState([
    { section: 'section1', ref: section1 },
    { section: 'section2', ref: section2 },
    { section: 'section3', ref: section3 },
    { section: 'section4', ref: section4 },
    { section: 'section5', ref: section5 },
]);
const List = [
  {
    id: 1,
    title: "Ticket Managment",
    sectionId: 'section1',
    sectionRef: section1
  },
  {
    id: 2,
    title: "Zia",
    sectionId: 'section2',
    sectionRef: section2
  },
  {
    id: 3,
    title: "Self-Service",
    sectionId: 'section3',
    sectionRef: section3
  },
  {
    id: 4,
    title: "Agent Productivity",
    sectionId: 'section4',
    sectionRef: section4
  },
  {
    id: 5,
    title: "Automation",
    sectionId: 'section5',
    sectionRef: section5
  },
];

useEffect(() => {
  const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const selected = sectionRefs.find(({ ref }) => {
          const ele = ref.current;
          if (ele) {
              const divDimension = getDimensions(ele);
              if (divDimension) {
                const { offsetBottom } = divDimension;

                  // TODO: Adjusted the scrollTo section position while scrolling. It previously handled as
                  // return scrollPosition < offsetTop && scrollPosition < offsetBottom;
                  return scrollPosition + 200 < offsetBottom;
              }
              return false;
          }
          return false;
      });

      console.log('selected', selected);
      if (selected && selected.section !== visibleSection) {
          setVisibleSection(selected.section);
      }
  };

  console.log('visibleSection', visibleSection);

  if (isBrowser) {
      handleScroll();
      document.addEventListener('scroll', handleScroll, true);
  }
  return () => {
      window.removeEventListener('scroll', handleScroll);
  };
}, [isBrowser, sectionRefs, visibleSection]);

const menuItem = (item, i) => {
  return (
    <li
        key={i}
        className={
          visibleSection === item?.sectionId ? "active mt-5" : " mt-5"
        }
        onClick={() => {
          if (item?.sectionRef?.current) {
            scrollTo(item?.sectionRef.current)
          }
          }
        }
      >
        <a
          href={`#section-${i}`}
          style={{
            color:
              visibleSection === item?.sectionId ? "#f00" : "#999",
            fontSize: 17,
            fontWeight: 600,
          }}
          className="whitespace-nowrap"
        >
          {item.title}
        </a>
      </li>
  )
}

const rightSideSection = () => {
  return (
    <>
      <div
        id={'section1'}
        ref={section1}
        className={
          visibleSection === section1?.current?.id ? "active" : ""
        }
        style={{
          backgroundColor: `#FFF`,
          paddingBlockStart: 80,

          color: "#000",
          fontSize: "2rem",
        }}
      >
        <ScrollCard />
      </div>

      <div
        id={'section2'}
        ref={section2}
        className={
          visibleSection === section2?.current?.id ? "active" : ""
        }
        style={{
          backgroundColor: `#FFF`,
          paddingBlockStart: 80,

          color: "#000",
          fontSize: "2rem",
        }}
      >
        <ScrollCard />
      </div>

      <div
        id={'section3'}
        ref={section3}
        className={
          visibleSection === section3?.current?.id ? "active" : ""
        }
        style={{
          backgroundColor: `#FFF`,
          paddingBlockStart: 80,

          color: "#000",
          fontSize: "2rem",
        }}
      >
        <ScrollCard />
      </div>

      <div
        id={'section4'}
        ref={section4}
        className={
          visibleSection === section4?.current?.id ? "active" : ""
        }
        style={{
          backgroundColor: `#FFF`,
          paddingBlockStart: 80,

          color: "#000",
          fontSize: "2rem",
        }}
      >
        <ScrollCard />
      </div>

      <div
        id={'section5'}
        ref={section5}
        className={
          visibleSection === section5?.current?.id ? "active" : ""
        }
        style={{
          backgroundColor: `#FFF`,
          paddingBlockStart: 80,

          color: "#000",
          fontSize: "2rem",
        }}
      >
        <ScrollCard />
      </div>
    </>
  )
}

  return (
    <div className="App">
      <div className="flex flex-row">
            <ul
              className="flex md:block gap-x-10 md:gap-x-0"
              data-cy="nav-wrapper"
              style={{
                listStyle: "none",
                position: "sticky",
                top: 72,
                backgroundColor: "#fff",
                paddingInline: 100,
                paddingBlockStart: 20,
                height: "100vh",
                width: 300,
              }}
            >
              {List.map((item, i) => {
                return menuItem(item, i)
              })}
            </ul>
            <div
              data-cy="section-wrapper"
              style={{
                width: "100%",
              }}
              ref={mainRef}
            >
              {rightSideSection()}
            </div>
          </div>
    </div>
  );
}
