import SwiperSlider from './SwiperTestimonial';
import { Heading } from '../../components/CustomTexts';
import { TestimonialsImg1, TestimonialsImg2, TestimonialsImg3 } from '../../assets';


const Slides = [
  {
    img: TestimonialsImg1,
    cite: `
            <cite className="flex flex-col p-3"><span className="elementor-testimonial__name">Dhilip Kumar,</span>
            <span className="elementor-testimonial__company"> Marketing Manager </span>
            </cite>
        `,
    content: `
            <div className="swiper-slide-contents p-3 space-y-2">
            <div className="elementor-testimonial__text font-bold">Ananya’s Garden</div>
            <div className="elementor-testimonial__text">Our Business and the Realiti tool get along seamlessly. Instead of projecting the entire project, we were able to project a specific flat/villa to our potential customer. It also allows us to adjust the unit’s availability on the go. It provides a lot of extra features, such as the ability to add amenities, photographs, and so on.</div>
            </div>`,
  },
  {
    img: TestimonialsImg2,
    cite: `
            <cite className="flex flex-col p-3"><span className="elementor-testimonial__name">Dhilip Kumar,</span>
            <span className="elementor-testimonial__company"> Marketing Manager </span>
            </cite>
        `,
    content: `
            <div className="swiper-slide-contents p-3 space-y-2">
            <div className="elementor-testimonial__text font-bold">Ananya’s Garden</div>
            <div className="elementor-testimonial__text">Our Business and the Realiti tool get along seamlessly. Instead of projecting the entire project, we were able to project a specific flat/villa to our potential customer. It also allows us to adjust the unit’s availability on the go. It provides a lot of extra features, such as the ability to add amenities, photographs, and so on.</div>
            </div>`,
  },
  {
    img: TestimonialsImg3,
    cite: `
            <cite className="flex flex-col p-3"><span className="elementor-testimonial__name">Dhilip Kumar,</span>
            <span className="elementor-testimonial__company"> Marketing Manager </span>
            </cite>
        `,
    content: `
            <div className="swiper-slide-contents p-3 space-y-2">
            <div className="elementor-testimonial__text font-bold">Ananya’s Garden</div>
            <div className="elementor-testimonial__text">Our Business and the Realiti tool get along seamlessly. Instead of projecting the entire project, we were able to project a specific flat/villa to our potential customer. It also allows us to adjust the unit’s availability on the go. It provides a lot of extra features, such as the ability to add amenities, photographs, and so on.</div>
            </div>`,
  },
]

const Testimonials = () => {
  return (
    <>
      <div className="relative pt-14 lg:pt-10">
        <div>
          <Heading
            title={<>Testimonials</>}
            textStyles="text-center"
          />
        </div>
        <div className="flex flex-row items-center justify-center">
          <div className="hidden xl:block flex-col  w-11/12  ">
            <SwiperSlider
              slidesPerView={1}
              slides={Slides}
              spaceBetween={40}
              slideDeley={5000}
            />
          </div>

          <div className="block xl:hidden pt-3 px-3 w-full">
            <SwiperSlider
              slidesPerView={1}
              slides={Slides}
              spaceBetween={40}
              slideDeley={5000}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default Testimonials
