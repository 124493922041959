"use client";

import { motion } from "framer-motion";

import { fadeIn } from "../utils/motion";
import Button from "./common/Button";

const PricingCard = ({ key, bg, heading, index, subheading, price, list, headtitle, subtitle }) => {
    return (
        <motion.div
            variants={fadeIn("right", "spring", index * 0.5, 0.75)}
            key={index}
            className="w-80 h-[520px] flex flex-col p-5  text-left text-gray-900 bg-white rounded-20px border border-slate-100 shadow ">
            <h3 className="mb-3 text-3xl font-medium text-secondary">{heading}</h3>
            <p className="font-light text-gray-500 sm:text-[14px] ">{subheading}</p>
            <div className="flex justify-start items-baseline my-8">
                <span className="mr-2 text-5xl font-extrabold">$ {price}</span>
                <span className="text-gray-800 text-base ">/ {index === 1 ? "Project" : "Year"}</span>
            </div>
            <p className="font-medium mb-4 text-black sm:text-lg ">{headtitle}</p>
            {subtitle ?
                <p className="font-medium mb-4 text-black sm:text-base  ">{subtitle}</p> : null
            }
            <ul className="mb-6 space-y-4 text-left">
                {
                    list.map((data, index) => {
                        return (
                            <li key={index} className="flex items-center space-x-3 text-sm ">
                                <svg className="flex-shrink-0 w-5 h-5 text-secondary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span>{data}</span>
                            </li>
                        )
                    })
                }

            </ul>
            <div className="flex items-center justify-center">
                <Button text="PAY NOW" to="" border="rounded-20px" width="w-3/4" />
            </div>
        </motion.div >

    );
};

export default PricingCard;
