"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { fadeIn } from "../utils/motion";

export const Step = ({ paragraph, text }) => (
  <div className={`${styles.flexStart} flex-col space-y-1 `}>
    <div className="flex flex-row space-x-4  items-center justify-center">
      <div className={`w-[8px] h-[8px] text-primary`}>
        <svg
          width="14"
          height="14"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18.707 9.707L11.147 17.267C10.959 17.455 10.705 17.56 10.44 17.56C10.175 17.56 9.92 17.455 9.733 17.267L6.28 13.814C5.889 13.423 5.889 12.791 6.28 12.4C6.671 12.009 7.303 12.009 7.694 12.4L10.44 15.146L17.293 8.293C17.684 7.902 18.316 7.902 18.707 8.293C19.098 8.684 19.098 9.316 18.707 9.707Z"
            fill="#F7991E"
          />
        </svg>
      </div>
      <h1 className="font-normal text-[15px] text-white">{text}</h1>
    </div>
    <p className="ml-[10px] font-normal text-[14px] text-white">
      {paragraph}
    </p>
  </div>
);

const FutureCard = ({
  key,
  title,
  list,
  index,
  paragraph,
}) => {
  return (
    <motion.div
      variants={fadeIn("right", "spring", index * 0.5, 0.75)}
      key={key}
      className={`w-full p-1 lg:px-4 py-4 flex  flex-col space-y-2 items-center  justify-center border-white rounded-20px border`}>
      <div className="p-4 lg:p-6 w-full text-left  items-start justify-start space-y-4 ">
        <h1 className="text-base  xl:text-[20px] font-bold text-primary leading-6 ">
          {title}
        </h1>
        <p className="text-sm w-10/12 lg:w-4/5  text-white">{paragraph}</p>
        <div className="flex flex-col items-start justify-start space-y-6">
          {list.map((item, index) => {
            return <Step key={index} text={item.head} paragraph={item.sublist} />;
          })}
        </div>
      </div>
    </motion.div>
  );
};

export default FutureCard;
