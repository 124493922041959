import { useEffect, useState } from 'react'

export const IS_BROWSER = typeof window !== 'undefined';

export const useIsBrowser = () => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
      if (IS_BROWSER) {
          setIsBrowser(true);
      }
  }, []);
  return isBrowser;
};

export const getDimensions = (ele) => {
    if (isElement(ele)) {
        const rect = ele.getBoundingClientRect();
        const { height } = rect;
        const { offsetTop } = ele;

        const offsetBottom = offsetTop + height;

        return {
            height,
            offsetTop,
            offsetBottom
        };
    }
    return null;
};

export const isElement = (element) => {
    if (!element) return false;
    return element instanceof Element || element instanceof HTMLDocument;
};

export const scrollTo = (ele, headerOffset = 95) => {
    if (IS_BROWSER) {
        const elementPosition = ele.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        console.log('elementPosition', elementPosition, 'offsetPosition', offsetPosition);

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }
};