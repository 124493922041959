"use client";

import { motion } from "framer-motion";

import { fadeIn } from "../utils/motion";

const EnjoyCard = ({ key, title, index, paragraph, icon }) => {
    return (
        <motion.div
            variants={fadeIn("right", "spring", index * 0.5, 0.75)}
            key={key}
            className={`w-full p-5 flex flex-col space-y-2 items-start  justify-start `}>
            <div className={`flex items-center justify-center`}>
                <img src={icon} alt={title} className="w-12 h-12" />
            </div>
            <div className="w-full text-left  items-start justify-start space-y-4 ">
                <h1 className="text-base  xl:text-[16px] font-medium text-black leading-8 ">
                    {title}
                </h1>
                <p className="text-base  text-font_primary">{paragraph}</p>
            </div>
        </motion.div>
    );
};

export default EnjoyCard;
