'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import { staggerContainer } from '../utils/motion';
import { Heading, SubHeading } from '../components/CustomTexts';
import { EasiestImg } from '../assets';

const EasiestPoint = () => (
    <section className={`${styles.paddings} relative z-10 flex items-center justify-center `}>
        <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidth} w-full flex  flex-col items-center justify-center`}
        >
            <SubHeading
                title={<>Easiest Point-of-Sale Software


                </>}
                textStyles="text-left"
            />
            <Heading title={<> Unified buyer experience across <br /> <span className="text-primary">online  </span> & offline channels</>} textStyles="text-center mb-4" />

            <div className='flex items-center justify-center flex-col'>
                <img src={EasiestImg} alt="home-connect" className=' w-full lg:w-4/6' />
            </div>


        </motion.div>
    </section>
);

export default EasiestPoint;
