import { AddressIcon1, AddressIcon2, AddressIcon3, Client1, Client2, Client3, Client4, Client5, Client6, ConfidentlyImg1, ConfidentlyImg2, ConfidentlyImg3, EmergeIcon1, EmergeIcon2, EmergeIcon3, EmergeIcon4, EnjoyIcon1, EnjoyIcon2, EnjoyIcon3, EnjoyIcon4, FeatureIcon1, FeatureIcon2, FeatureIcon3, FeatureIcon4, InsightImg1, InsightImg2, InsightImg3, InsightImg4, ManageImg1, ManageImg2, ManageImg3, MissionImg1, MissionImg2, MissionImg3, PartnerImg1, PartnerImg2, PartnerImg3, PartnerImg4, PartnerImg5, PartnerImg6, PriceImg1, PriceImg2, PriceImg3, TagIcon, UploadIcon, UserIcon } from "../assets"


export const ExperiencedData = [
  {
    number: '15+ yrs',
    exp: 'Industry Exp',
  },
  {
    number: '8 lac - 5 Cr worth',
    exp: 'Product Planning Exp',
  },

  {
    number: '21 Lac Sqft',
    exp: 'Marketing Exp',
  },
  {
    number: '400 Cr',
    exp: 'Collection Exp',
  },
  {
    number: '10000 +',
    exp: 'Customer Handling Exp',
  },
]

export const ManageData = [
  {
    id: 'm-1',
    title: 'Manage Faster. Online.',
    paragraph: 'Receive instant notifications whenever home seeker enquires your unit home. Connect with the home seekers ..',
    bg: 'bg-[#FAF3E5]',
    icon: ManageImg1,
  },
  {
    id: 'm-2',
    title: 'Market Faster. Online',
    paragraph: 'Every model unit home has different target group, then why generate project enquiries. Every unit home is now…',
    bg: 'bg-[#F2F1EF]',
    icon: ManageImg2,
  },
  {
    id: 'm-3',
    title: 'Sell Faster. Online.',
    paragraph: 'Be present wherever your home seekers search. Our Live sync enables your unit home seekers to be available,… ',
    bg: 'bg-[#FFE9D1]',
    icon: ManageImg3,
  },
]

export const ProductData = [
  {
    id: 'm-1',
    title: 'Online Store',
    paragraph: 'Receive instant notifications whenever home seeker enquires your unit home. Connect with the home seekers ..',
    bg: 'bg-[#F8E9D6]',
    icon: ManageImg1,
  },
  {
    id: 'm-2',
    title: 'MLS',
    paragraph: 'Every model unit home has different target group, then why generate project enquiries. Every unit home is now…',
    bg: 'bg-[#F1F0ED]',
    icon: ManageImg2,
  },
  {
    id: 'm-3',
    title: 'IDX',
    paragraph: 'Be present wherever your home seekers search. Our Live sync enables your unit home seekers to be available,… ',
    bg: 'bg-[#F8E9D6]',
    icon: ManageImg3,
  },
  {
    id: 'm-4',
    title: 'Lead Magnet',
    paragraph: 'Be present wherever your home seekers search. Our Live sync enables your unit home seekers to be available,… ',
    bg: 'bg-[#F1F0ED]',
    icon: ManageImg3,
  },
]



export const PricingData = [

  {
    id: 'p-1',
    heading: 'Broker',
    subheading: 'Yes you read it right. It’s the annual pricing',
    price: '299',
    headtitle: "What's included",
    list: [
      'Custom branded webstore',
      'Real-time insights',
      'Access to partner network',
      'Unlimited Leads',
      'Unlimited shared inventories'
    ]
  },
  {
    id: 'p-2',
    heading: 'Builders',
    subheading: 'Minimal price. Maximised outcomes',
    price: '899',
    headtitle: "What's included",
    subtitle: 'All in broker plus',
    list: [
      'Unit level property pages',
      'Images and videos',
      'SEO and Social Media',
      'Custom reports',
    ]
  }
]

export const CategoriesData = [
  {
    id: 0,
    title: 'Search',
    icon: PriceImg1
  },
  {
    id: 1,
    title: 'Filter',
    icon: PriceImg2
  },
  {
    id: 2,
    title: 'Categories',
    icon: PriceImg3
  }

]


export const EmergeData = [
  {
    id: 'r-1',
    title: 'Centralising all properties',
    paragraph: 'Get real-time updates on your properties.',
    icon: EmergeIcon1,
  },
  {
    id: 'r-2',
    title: 'Make More Money',
    paragraph: 'Close deals quickly by giving what your clients prefer.',
    icon: EmergeIcon2,
  },
  {
    id: 'r-3',
    title: 'Be Future Ready',
    paragraph: 'Digitally transform your business to grow faster.',
    icon: EmergeIcon3,
  },
  {
    id: 'r-4',
    title: 'Grow Your Own Private Network',
    paragraph: 'Connect with fellow brokers, builders and bankers',
    icon: EmergeIcon4,
  },
]

export const EnjoyData = [
  {
    id: 'e-1',
    title: 'Webstore',
    paragraph: 'Easily build webstore with high converting landing pages at unit levels.',
    icon: EnjoyIcon1,
  },
  {
    id: 'e-2',
    title: 'Omnichannel marketing',
    paragraph: 'Expand your reach by managing SEO and marketplace in one place.',
    icon: EnjoyIcon2,
  },
  {
    id: 'e-3',
    title: 'Ecommerce',
    paragraph: 'Enable your customers easily search and purchase their property online. First of kind!',
    icon: EnjoyIcon3,
  },
  {
    id: 'e-4',
    title: 'Comprehensive Support',
    paragraph: 'Get in touch with us via email, phone, or chat to resolve your queries.',
    icon: EnjoyIcon4,
  },
]



export const FeaturesData = [
  {
    id: 'f-1',
    title: 'Take ownership',
    paragraph: 'Have complete control of your partner network. Your stocks, your way!',
    icon: FeatureIcon1
  },
  {
    id: 'f-2',
    title: 'Make more money',
    paragraph: 'Keep 100% of everything you earn. Just a fixed platform subscription fee.',
    icon: FeatureIcon2
  },
  {
    id: 'f-3',
    title: 'Keeping you busy',
    paragraph: 'With a huge network of builders and scope to act as sub-agents, we have your back!',
    icon: FeatureIcon3
  },
  {
    id: 'f-4',
    title: 'Get expertly matched',
    paragraph: 'We understand where you’re great at, and give you projects matching your expertise',
    icon: FeatureIcon4
  },
]


export const PartnerData = [
  {
    id: 'f-1',
    title: 'Partner confidently',
    paragraph: 'We manually verify all the realtors for RERA compliance',
    icon: ConfidentlyImg1
  },
  {
    id: 'f-2',
    title: 'Get full transparency',
    paragraph: 'View their track record, testimonials, and work experience',
    icon: ConfidentlyImg2
  },
  {
    id: 'f-3',
    title: 'Ensure quick TATs',
    paragraph: 'Get matched with the right partners almost immediately',
    icon: ConfidentlyImg3
  },
]

export const MissionData = [
  {
    id: 'm-1',
    paragraph: 'We organize every new  home in the primary market',
    icon: MissionImg1
  },
  {
    id: 'm-2',
    paragraph: 'We empower the home providers (promoters/builders) to showcase, track, and react to the market',
    icon: MissionImg2
  },
  {
    id: 'm-3',
    paragraph: 'We empower the home seekers to search and showcase their requirements to the market',
    icon: MissionImg3
  },
]



export const ProfileData = [
  {
    id: 1,
    title: 'Create a realiti profile',
    paragraph: 'Complete the due diligence, add stocks, and build your folio.'
  },
  {
    id: 2,
    title: 'Get approved',
    paragraph: 'We allow only RERA certified realtors. We’ll help you with it!'
  },
  {
    id: 3,
    title: 'Scale your business',
    paragraph: 'Get matched with stocks from the country’s best builders'
  },
  {
    id: 4,
    title: 'Grow to be the next big realtor',
    paragraph: 'Get all resources to become a superbroker with Realiti.io'
  },
]

export const CuriousData = [
  {
    id: 1,
    title: 'Our platform is user-owned',
    paragraph: 'Our realtors own the network and get to keep 100% of what they earn.'
  },
  {
    id: 2,
    title: 'We’re cost effective',
    paragraph: 'Fixed subscription fee, and nothing beyond. Nothing beyond!'
  },
  {
    id: 3,
    title: 'Provide real time updates',
    paragraph: 'We give you a quality guarantee'
  },
  {
    id: 4,
    title: 'Grow to be the next big realtor',
    paragraph: 'We know what it takes for a realtor to become a superstar'
  },
]


export const FutureData = [
  {
    id: 'r-1',
    title: 'For Brokers',
    paragraph: 'Sign up today to join the thriving community of builders and fellow brokers With house bullets the following texts',
    list: [
      {
        head: 'Secure your future',
        sublist: 'Stop Worrying about what’s next. Queue up all the upcoming projects.'
      },
      {
        head: 'Keep 100% of what you earn',
        sublist: 'Stop Worrying about what’s next. Queue up all the upcoming projects.'
      },
      {
        head: 'Take control and ownership',
        sublist: 'Realiti is user owned. You have complete control of the stocks you own, networks you build, and leads you receive.'
      }
    ]
  },
  {
    id: 'r-2',
    title: 'For Builders',
    paragraph: 'Join us today, and find scope to improve your lead-to-sale ratio in the next 48 hours.',
    list: [
      {
        head: 'Skip all hassles to hire the best',
        sublist: 'Identify the best brokers at a hyper-local area and collaborate with them'
      },
      {
        head: 'Get full transparency and control',
        sublist: 'Get the complete portfolio of the broker and provide. View work history, client testimonials, sample projects, and more before you make a hire.'
      },
      {
        head: 'Make your budget go 3-4x further',
        sublist: 'Consultants and agencies charge up to 70% in markups. At Braintrust you pay just 10%.'
      }
    ]
  },

]
export const startingFeatures = [
  'Instantly onboard new hires anywhere in the world',
  'Run global payroll securely and with a single click',
  'Stay 100% compliant with local employment laws',
  'Manage benefits, work permits, timesheets, and more',
]

export const solutionList = [
  'Connect & build your private community',
  'Collaborate with fellow brokers, builders, and bankers',
  'Communicate effectively with your buyers'
]

export const easyStepList = [
  {
    id: 1,
    imgUrl: UserIcon,
    title: 'Register',
    subtitle: 'Sign up and get your KYC & RERA verified',
  },
  {
    id: 2,
    imgUrl: UploadIcon,
    title: 'Upload',
    subtitle: 'Upload your properties, and share with fellow brokers',
  },
  {
    id: 3,
    imgUrl: TagIcon,
    title: 'Sell',
    subtitle: 'Go live with your site to attract buyers from anywhere',
  },
]

export const newFeatures = [
  {
    imgUrl: '/vrpano.svg',
    title: 'A new world',
    subtitle:
      'we have the latest update with new world for you to try never mind',
  },
  {
    imgUrl: '/headset.svg',
    title: 'More realistic',
    subtitle:
      'In the latest update, your eyes are narrow, making the world more realistic than ever',
  },
]

export const insights = [
  {
    id: '01',
    imgUrl: InsightImg1,
    title: 'Do It Yourself Website',
    subtitle:
      'It is as simple as how you would use Whatsapp. With Realiti.io it just take three simple stepsto get your real estate business up and running…',
    bg: 'bg-[#FEF0F0]'
  },
  {
    id: '02',
    imgUrl: InsightImg2,
    title: 'Complete your profile',
    subtitle:
      'Get all your KYCs done for once so that the partner onboarding is never a tedious process to be done over and again.',
    bg: 'bg-[#F0F7FF]'
  },
  {
    id: '03',
    imgUrl: InsightImg3,
    title: 'Content for website and communications',
    subtitle:
      'Add or source stocks from your partners that can be showcasedon your website.',
    bg: 'bg-[#F3ECFA]'
  },
  {
    id: '04',
    imgUrl: InsightImg4,
    title: 'Check for domain availability',
    subtitle:
      'Go Live with your webstore with a template of your choice and start marketing your real estate brand seamlessly.',
    bg: 'bg-[#FFF5E9]'
  },
]

export const socials = [
  {
    name: 'twitter',
    url: '/twitter.svg',
  },
  {
    name: 'linkedin',
    url: '/linkedin.svg',
  },
  {
    name: 'instagram',
    url: '/instagram.svg',
  },
  {
    name: 'facebook',
    url: '/facebook.svg',
  },
]

export const PartnerLogoData = [
  {
    name: 'client1',
    url: PartnerImg1,
  },
  {
    name: 'client1',
    url: PartnerImg2,
  },

  {
    name: 'client1',
    url: PartnerImg3,
  },
  {
    name: 'client1',
    url: PartnerImg4,
  },
  {
    name: 'client1',
    url: PartnerImg5,
  },
  {
    name: 'client1',
    url: PartnerImg6,
  },


]

export const client = [
  {
    name: 'client1',
    url: Client1,
  },
  {
    name: 'client1',
    url: Client2,
  },

  {
    name: 'client1',
    url: Client6,
  },
  {
    name: 'client1',
    url: Client3,
  },
  {
    name: 'client1',
    url: Client5,
  },
  {
    name: 'client1',
    url: Client4,
  },


]

export const BlogSublist = [

  {
    id: 'new-project-creation',
    title: 'New Project Creation'
  },
  {
    id: 'my-stock-workflow',
    title: 'My Stock Workflow'
  },
  {
    id: 'my-lead-ads',
    title: 'My Lead ads'
  },
  {
    id: 'builder-workflow',
    title: 'Builder Workflow'
  },
  {
    id: 'broker-workflow',
    title: 'Broker Workflow'
  },
  {
    id: 'banker-workflow',
    title: 'Banker Workflow'
  },
  {
    id: 'add-stock-figma-workflow',
    title: 'Add Stock Figma Workflow'
  }


]


export const blogData = [
  {
    id: 1,
    slug: 'new-project-creation',
    title: 'New Project Creation',
    content: 'The CSS Grid Layout Module offers a grid-based layout system, with rows & columns, making it easier to design web pages.'
  },
  {
    id: 2,
    slug: 'my-stock-workflow',
    title: 'My Stock Workflow',
    content: 'The CSS Grid Layout Module offers a grid-based layout system, with rows & columns, making it easier to design web pages.'

  },
  {
    id: 3,
    slug: 'my-lead-ads',
    title: 'My Lead ads',
    content: 'The CSS Grid Layout Module offers a grid-based layout system, with rows & columns, making it easier to design web pages.'

  },
  {
    id: 4,
    slug: 'builder-workflow',
    title: 'Builder Workflow',
    content: 'The CSS Grid Layout Module offers a grid-based layout system, with rows & columns, making it easier to design web pages.'

  },
  {
    id: 5,
    slug: 'broker-workflow',
    title: 'Broker Workflow',
    content: 'The CSS Grid Layout Module offers a grid-based layout system, with rows & columns, making it easier to design web pages.'

  },
  {
    id: 6,
    slug: 'banker-workflow',
    title: 'Banker Workflow',
    content: 'The CSS Grid Layout Module offers a grid-based layout system, with rows & columns, making it easier to design web pages.'

  },
  {
    id: 7,
    slug: 'add-stock-figma-workflow',
    title: 'Add Stock Figma Workflow',
    content: 'The CSS Grid Layout Module offers a grid-based layout system, with rows & columns, making it easier to design web pages.'

  }
]

export const ContactData = [
  {
    id: 1,
    imgUrl: AddressIcon1,
    title: 'Our main office',
    subtitle: '165, West Sambandam Road, RS Puram, Coimbatore - 641 002.',
  },
  {
    id: 2,
    imgUrl: AddressIcon2,
    title: 'Phone number',
    subtitle: '+91 97912 48260',
  },
  {
    id: 3,
    imgUrl: AddressIcon3,
    title: 'Email address',
    subtitle: 'mailus@realiti.io',
  },
]
