'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import { PartnerLogoData } from '../constants';
import { fadeIn, staggerContainer } from '../utils/motion';
import { Heading, SubHeading } from '../components/CustomTexts';

export const PartnerLogo = ({ key, id, url, title, index, paragraph, bg, text, icon }) => {

    return (


        <motion.div
            variants={fadeIn('left', 'spring', index * 0.5, 1)}
            key={key}
            className={`w-full ${index % 2 === 0 ? '' : 'bg-[#F8F8F8] shadow-md shadow-slate-100 '}p-4 flex rounded-10px flex-col space-y-2 items-center  justify-center  `}
        >
            <img src={url} alt={title} className='' />
        </motion.div>
    )
}



const Partners = () => {

    return (
        <section className={`${styles.paddings}`} >
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.25 }}
                className={`${styles.innerWidth} mx-auto flex flex-col`}
            >
                <SubHeading
                    title={<>Partners</>}
                    textStyles="text-center"
                />
                <Heading title={<> Our Associates </>} textStyles="text-center mb-4" />


                <p className="px-3 lg:px-24 text-center text-sm md:text-base font-bold font-Poppins tracking-normal text-black xl:leading-snug">
                    Realiti has successful business partnerships with Sree Gopal & Co, Asvika, IndusLaw, FranCorp, Central bank of india and city union bank to help maintain world-class standards in all our operations. These associations help us scale, accelerate our growth and serve our customers better.
                </p>
                <div className="mt-[50px] flex lg:flex-row flex-col   gap-5">
                    {PartnerLogoData.map((logos, index) => (
                        <PartnerLogo
                            key={index}
                            {...logos}
                            index={index}
                        />
                    ))}
                </div>
            </motion.div>
        </section>
    );
};

export default Partners;
