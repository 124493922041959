import React from "react";
import { DoneSvg, WrongSvg } from "../assets";

const PriceTable = () => {
  const TableData = [
    {
      id: 1,
      title: "Website Development",
    },
    {
      id: 2,
      title: "Portal like 99acres",
    },
    {
      id: 3,
      title: "Tech Support Staff(1px)",
    },
    {
      id: 4,
      title: "DB & Servers(AWS)",
    },
    {
      id: 5,
      title: "Physical & IT Infra",
    },
    {
      id: 6,
      title: "Maintenance",
    },
  ];

  const category = [
    "Custom website designs",
    "Testimonial display",
    "Unlimited posts and pages",
  ];

  return (
    <>
      <div className="mx-auto space-y-10">
        <div className=" bg-white flex flex-col xl:flex-row items-center justify-center overflow-hidden">
          <div className="w-full lg:w-4/5 px-2 lg:px-8 mb-10 bg-white shadow-lg rounded-30px">
            <div className="bg-white  my-6">
              <table className="w-full table-auto">
                <thead>
                  <tr className="  text-black">
                    <th className="py-4 px-6 text-left"></th>
                    <th className="py-4  px-2 lg:px-6 text-[10px] lg:text-[15px] text-center lg:text-left">
                      Realiti.io <br />
                      <span className="text-[10px] lg:text-[16px] text-center">
                        ₹24000/year
                      </span>
                    </th>
                    <th className="py-4 px-2 lg:px-6 text-[10px] lg:text-[15px] text-center lg:text-left">
                      Other Expensive Methods
                      <br />
                      <span className="text-[10px] lg:text-[16px] text-center">
                        {" "}
                        ₹13,73,000 /year
                      </span>
                    </th>
                  </tr>
                </thead>
                {TableData.map((tdata, i) => {
                  return (
                    <>
                      <thead key={i}>
                        <tr className="bg-[#F0F7FF] text-black text-xs  lg:text-sm ">
                          <th className="py-4 px-6 text-left w-1/2">
                            {tdata.title}
                          </th>
                          <th className="py-4 px-6 text-left w-1/4 ">
                            <img src={DoneSvg} alt="done" />
                          </th>
                          <th className="py-4 px-2 lg:px-6 items-start  justify-start text-left flex flex-row space-x-1">
                            <img src={WrongSvg} alt="w" />
                            {/* <span className="text-[8px] lg:text-[12px] font-normal">
                              ₹ 25,000
                            </span> */}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 text-xs lg:text-sm font-light">
                        {category.map((data, i) => {
                          return (
                            <tr className="border-b border-gray-200">
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <div className="flex items-center">
                                  <span className="font-medium">{data}</span>
                                </div>
                              </td>

                              <td className="py-3 px-6 text-left">
                                <div className="flex items-start">
                                  <img src={DoneSvg} alt="done" />
                                </div>
                              </td>
                              <td className="py-4 px-2 lg:px-6 items-start  justify-start text-left flex flex-row space-x-1">
                                <img src={WrongSvg} alt="w" />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceTable;
