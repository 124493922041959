import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import { staggerContainer } from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar } from "../components";
import { BgGradient } from "../assets";
import { HashLink } from 'react-router-hash-link';



export const Sublist = [

    {
        id: 1,
        title: '1. Returns Policy',
        slug: 'returns-policy'
    },
    {
        id: 2,
        title: '2. Additional non-returnable items',
        slug: 'additional-non-returnable-items'
    },
    {
        id: 3,
        title: '3. There are certain situations where only partial refunds are granted',
        slug: 'there-are-certain-situations-where-only-partial-refunds-are-granted'
    },
    {
        id: 4,
        title: '4. Refunds',
        slug: 'refunds'
    },
    {
        id: 5,
        title: '5. Late or missing refunds',
        slug: 'late-or-missing-refunds'
    },
    {
        id: 6,
        title: '6. Sales items',
        slug: 'sales-items'
    },
    {
        id: 7,
        title: '7. Exchanges',
        slug: 'exchanges'
    },
    {
        id: 8,
        title: '8. Gifts',
        slug: 'gifts'
    },
    {
        id: 9,
        title: '9. Shipping returns',
        slug: 'shipping-returns'
    },
    {
        id: 10,
        title: '10. Need Help?',
        slug: 'need-help'
    }
]

export const FeaturesTabItem = ({ key, title, slug, id }) => {
    return (
        <>
            <div
                key={key}
                className={`w-full flex flex-col items-start  px-2 cursor-pointer`}>
                <div className="  flex flex-col px-4 py-1">
                    <HashLink to={`/refund-policy/#${slug}`}
                        className={` hover:text-primary text-[#2A8AFF]
            text-left mt-[6px] font-medium font-Poppins md:text-[16px]  text-[14px] `}>
                        {title}
                    </HashLink>
                </div>
            </div>
        </>
    );
};

export const MobileTap = ({ children }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="py-1.5 bg-white rounded-sm">
            <div
                className={`h-1 bg-primary`}
                style={{ width: `100%`, transition: "width 10s ease-in-out" }}></div>
            <div
                onClick={() => {
                    setOpen(!open);
                }}
                className={`px-4 py-5 flex flex-row items-center justify-between `}>
                <p className={`font-medium text-lg text-black`}>
                    Table of contents
                </p>
                <div
                    className={`${open ? "text-primary rotate-180" : "text-font_secondary rotate-0"
                        } transform transition-all ease-in duration-300`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                    </svg>
                </div>
                {/* <DropDownIcon classNames={`${currentFeature === index && open ? "text-primary rotate-180" : "text-font_secondary rotate-0"} transform transition-all ease-in duration-300`} /> */}
            </div>
            {open ? (
                <div
                    className={`transition-all duration-500 ease-in-out overflow-hidden `}>
                    <div>{children}</div>
                </div>
            ) : null}
        </div>
    );
};

const RefundPolicy = () => {

    const [showFloatHeader, setShowFloatHeader] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);


    useEffect(() => {
        document.getElementById("layout").addEventListener("scroll", () => {
            document.getElementById("layout").scrollTop > 50
                ? setShowFloatHeader(true)
                : setShowFloatHeader(false);
        });
    });


    return (
        <Layout
            id="layout"
            showFloatHeader={showFloatHeader}
            showSideBar={showSideBar}
            setShowSideBar={setShowSideBar}>
            <div
                style={{ backgroundImage: `url(${BgGradient})` }}
                className="bg-no-repeat ">
                <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

                <section
                    className={`${styles.yPaddings} w-full px-4 lg:px-10  flex flex-col lg:flex-row`}>
                    <div className="relative w-full lg:w-5/6  mx-auto px-2 lg:px-4 ">
                        <div className="block lg:hidden mt-6">
                            <MobileTap>
                                {Sublist.map((feature, index) => {
                                    return (
                                        <div key={index}>
                                            <FeaturesTabItem
                                                key={index}
                                                slug={feature.slug}
                                                content={feature.content}
                                                title={feature.title}
                                            />
                                        </div>
                                    );
                                })}
                            </MobileTap>
                        </div>
                        <motion.div
                            variants={staggerContainer}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: true, amount: 0.25 }}
                            className={`${styles.innerWidth} relative mx-auto pb-10 flex flex-col`}>
                            <div id="returns-policy" className="w-full mt-8 xl:mt-4 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-6">
                                    <h1 className="text-[30px] lg:text-[40px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        Returns Policy
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        Our refund and returns policy lasts 14 days. If 14 days have passed since your purchase, we can’t offer you a full refund or exchange.
                                    </p>

                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        Several types of goods are exempt from being returned. Perishable goods such as food, flowers, newspapers or magazines cannot be returned. We also do not accept products that are intimate or sanitary goods, hazardous materials, or flammable liquids or gases.
                                    </p>

                                </div>
                            </div>


                            <div id="additional-non-returnable-items" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h3 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        Additional Non-returnable items
                                    </h3>


                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        1. Gift cards
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        2. Downloadable software products
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        3. Personal items
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        To complete your return, we require a receipt or proof of purchase.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        Please do not send your purchase back to the manufacturer.
                                    </p>

                                </div>
                            </div>

                            <div id="there-are-certain-situations-where-only-partial-refunds-are-granted" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        There are certain situations where only partial refunds are granted
                                    </h1>

                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        Book with obvious signs of use
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        CD, DVD, VHS tape, software, video game, cassette tape, or vinyl record that has been opened.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        Online resources that are not accessed/downloaded completely.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        Any item that is returned more than 14 days after delivery
                                    </p>

                                </div>
                            </div>

                            <div id="refunds" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        Refunds
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        Once your return request is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
                                    </p>

                                </div>
                            </div>

                            <div id="late-or-missing-refunds" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        Late or Missing refunds                                   </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        If you haven’t received a refund yet, first check your bank account again.

                                    </p>

                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        Then contact your credit card company, it may take some time before your refund is officially posted.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        Next contact your bank. There is often some processing time before a refund is posted.
                                        Sale items
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        If you’ve done all of this and you still have not received your refund yet, please contact us at
                                        <a
                                            href="mailto:mailus@realiti.io"
                                            target="_blank"
                                            rel="noreferrer"
                                            className=" ml-2 mr-2 text-[#2A8AFF] text-12px font-normal hover:font-medium">
                                            mailus@realiti.io
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>

                            <div id="sale-items" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        Sale items
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        Only regular priced items may be refunded. Sale items cannot be refunded.
                                    </p>



                                </div>
                            </div>

                            <div id="exchanges" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        Exchanges
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        We only replace items if they are defective or damaged. If you need to exchange it for the same item, send us an email at <a
                                            href="mailto:mailus@realiti.io"
                                            target="_blank"
                                            rel="noreferrer"
                                            className=" ml-2 mr-2 text-[#2A8AFF] text-12px font-normal hover:font-medium">
                                            mailus@realiti.io
                                        </a> and send your item to the specified address.
                                    </p>
                                </div>
                            </div>

                            <div id="gifts" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        Gifts
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        If the item was marked as a gift when purchased and shipped directly to you, you’ll receive a gift credit for the value of your return. Once the returned item is received, a gift certificate will be mailed to you.

                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        If the item wasn’t marked as a gift when purchased, or the gift giver had the order shipped to themselves to give to you later, we will send a refund to the gift giver and they will find out about your return.
                                    </p>

                                </div>
                            </div>

                            <div id="shipping-returns" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        Shipping returns
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        To return your product, you should mail your product to: 165, 1st Floor, West Sambandam Road, R S Puram, Coimbatore - 641 002
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        Depending on where you live, the time it may take for your exchanged product to reach you may vary.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        If you are returning more expensive items, you may consider using a trackable shipping service or purchasing shipping insurance. We don’t guarantee that we will receive your returned item.
                                    </p>
                                </div>
                            </div>

                            <div id="need-help" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        Need help?
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        Contact us at <a
                                            href="mailto:mailus@realiti.io"
                                            target="_blank"
                                            rel="noreferrer"
                                            className=" ml-2 mr-2 text-[#2A8AFF] text-12px font-normal hover:font-medium">
                                            mailus@realiti.io
                                        </a> for questions related to refunds and returns.
                                    </p>

                                </div>
                            </div>

                        </motion.div>

                    </div>
                    <div className="w-full lg:w-1/5">
                        <div className="hidden w-full shadow-sm bg-white border pb-4 rounded-md space-x-2 lg:flex flex-col  overflow-x-hidden overflow-y-hidden">
                            <p className={`font-medium mt-8 mb-3 px-6 text-base text-black`}>
                                Table of contents
                            </p>
                            {Sublist.map((feature, index) => {
                                return (
                                    <div key={index}>
                                        <FeaturesTabItem
                                            key={index}
                                            slug={feature.slug}
                                            content={feature.content}
                                            // icon={feature.icon}
                                            title={feature.title}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </div>

            <div className="relative"></div>
            <Footer />
        </Layout>
    );
};

export default RefundPolicy;
