import React, { useEffect, useState } from 'react'
import Layout from "../components/Layout";
import Explore from "../sections/Explore";
import { motion } from "framer-motion";
import { staggerContainer, } from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar, TitleText } from "../components";
import Button from "../components/common/Button";
import PlayButton from "../components/common/PlayButton";
import { PlayIcon, BgGradient, Shape } from "../assets";
import TrustedBy from "../sections/TrustedBy";
import Solution from "../sections/Solution";
import Portal from "../sections/Portal";
import RealEstate from "../sections/RealEstate";
import Future from "../sections/Future";
import Testimonials from "../sections/Testimonial/Testimonial";
import Integrate from '../sections/Integrate';
import EasiestPoint from '../sections/EasiestPoint';
import Manage from '../sections/Manage';
import Product from '../components/Product';
import { CustomerTab } from '../components/Customer';

const Home = () => {

  const [showFloatHeader, setShowFloatHeader] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)



  useEffect(() => {
    document.getElementById('layout').addEventListener('scroll', () => {
      document.getElementById('layout').scrollTop > 50 ? setShowFloatHeader(true) : setShowFloatHeader(false);
    })
  })


  return (
    <Layout id="layout" showFloatHeader={showFloatHeader} showSideBar={showSideBar} setShowSideBar={setShowSideBar}>

      <div
        style={{ backgroundImage: `url(${BgGradient})` }}
        className="bg-no-repeat">

        <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

        <section className={`${styles.yPaddings} w-full bg-cover `}>
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.25 }}
            className={`${styles.innerWidth} relative w-4/5  mx-auto pb-6 flex flex-col-reverse xl:flex-row`}>
            <div className="hidden xl:flex items-center justify-end p-10">
              <img
                className="absolute left-0 mt-8 w-[25%]"
                width=""
                src={Shape}
                alt="Workplace"
              />
            </div>

            <div className="w-full xl:w-2/3 mt-8 xl:mt-10 mx-auto flex items-center justify-center">
              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="tex-base lg:text-lg capitalize font-Poppins font-medium tracking-normal lg:tracking-wide	">
                  EMERGE AS THE NEXT{" "}
                  <span className="text-primary">MAGICBRICKS </span>
                </h1>
                <TitleText
                  title={
                    <>
                      {" "}
                      Get your own
                      <span className="text-primary ml-2">
                        real estate
                      </span>{" "}
                      <br /> portal in 30 minutes.
                    </>
                  }
                  textStyles="text-center"
                />
                <p className="text-sm md:text-lg font-Poppins tracking-normal text-font_primary xl:leading-snug">
                  Zero technical knowledge required!
                </p>
                <div className="flex flex-row space-x-4 px-2">
                  <Button text="Try a free demo" to="https://platform.realiti.io/" />
                  <div>
                    <PlayButton
                      icon={PlayIcon}
                      text={
                        <>
                          <span className="text-primary">Watch</span>
                          <br /> Intro Video
                        </>
                      }
                      to="/"
                    />
                  </div>
                </div>
              </div>
            </div>

          </motion.div>
        </section>
      </div>

      <div className="">
        <Manage />
        <Product />
        <CustomerTab />
        <TrustedBy />
        <Solution />
        <Portal />
        <EasiestPoint />
        <Explore />
        <RealEstate />
        <Future />
        <Testimonials />
        <Integrate />
      </div>
      <Footer />
    </Layout>
  );
};

export default Home;
