"use client";

import { motion } from "framer-motion";

const InsightCard = ({ bg, imgUrl, id, title, subtitle, index }) => (
  <motion.div
    className={`flex p-6 lg:p-2  w-full lg:flex-row flex-col-reverse gap-2 lg:gap-4 ${bg} rounded-[28px]`}>
    <div className="w-full lg:w-3/5 flex justify-between items-center">
      <div className="flex-1 ml-0 md:ml-[58px] flex flex-col">
        <h1 className="font-Poppins font-bold lg:text-[44px] text-[24px] text-back">
          {id}
        </h1>
        <h4 className="font-Poppins font-bold lg:text-[28px] text-[20px] text-back">
          {title}
        </h4>
        <p className="mt-[10px] w-full lg:w-5/6  lg:text-[16px] text-[12px] text-font_primary">
          {subtitle}
        </p>
      </div>
    </div>
    <div className="w-full lg:w-2/5 flex items-center justify-center">
      <img
        src={imgUrl}
        alt="planet-01"
        className="md:w-[460px] w-[240px] h-[240px]  md:h-[380px]"
      />
    </div>
  </motion.div>
);

export default InsightCard;
