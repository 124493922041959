import { FolderIcon } from "../../assets/icons/FolderIcon"
import { MenuIcon } from "../../assets/icons/MenuIcon"
import { ProfileIcon } from "../../assets/icons/ProfileIcon"
import { ShareIcon } from "../../assets/icons/ShareIcon"
import { TeamFeedIcon } from "../../assets/icons/TeamFeedIcon"
import HighQualityImage from '../../assets/images/customer/head-quaters.svg'
import TeamFeeds from '../../assets/images/customer/team-feeds.svg'
import Views from '../../assets/images/customer/views.svg'
import AgentQue from '../../assets/images/customer/agent-que.svg'
import TeamQue from '../../assets/images/customer/team-que.svg'
import { useState } from "react"
import { MessageIcon } from "../../assets/icons/MessageIcon"
import { SearchIcon } from "../../assets/icons/SearchIcon"
import { GameIcon } from "../../assets/icons/gameIcon"
import { SettingsIcon } from "../../assets/icons/SettingsIcon"
export const CustomerTab=()=>{
    const [tabId,setTabId]=useState(0)
    const data=[
        {
            id:0,
            icon:<MenuIcon />,
            title:'HQ'
        },
        {
            id:1,
            icon:<TeamFeedIcon />,
            title:'Team Feeds'
        },
        {
            id:2,
            icon:<FolderIcon />,
            title:'Views'
        },
        {
            id:3,
            icon:<ProfileIcon />,
            title:'Agent Queue'
        },
        {
            id:4,
            icon:<ShareIcon />,
            title:"Team Queue"
        }
    ]

    const Image=[
        {
            id:0,
            image:HighQualityImage
        },
        {
            id:1,
            image: TeamFeeds,
        },
        {
            id:2,
            image: Views,
        },
        {
            id:3,
            image: AgentQue,
        },
        {
            id:4,
            image: TeamQue,
        }
    ]
    
const handelTab=(id)=>{
    setTabId(id)
}
    return(

        <section className="mx-auto my-7">
            <div className="text-center">
                <h1 className="lg:text-5xl text-base font-bold">Who says customer <br/> service is tough?</h1>
            </div>
            
            <div className="bg-[#2C354D] rounded-lg mt-10 max-w-[998px] mx-auto">
            <div className="p-5 flex lg:flex-row flex-col gap-y-5 lg:gap-y-0 justify-between items-center">
                <div className="flex sm:flex-row flex-col  items-center text-white sm:space-x-[54px]">
                    <MessageIcon />
                    <ul className="flex sm:flex-row flex-col  items-center gap-y-2.5 sm:gap-x-10">
                        <li className="underline underline-offset-[20px]">Tickets</li>
                        <li>Analytics</li>
                        <li>Knowledge Base</li>
                        <li>Social</li>
                    </ul>
                </div>
                <div className="flex items-center text-white gap-x-5">
                    <h6>All Departments</h6>
                    <SearchIcon />
                    <GameIcon />
                    <SettingsIcon/>
                </div>
            </div>
                <div className="flex">
                    <ul>
                       {data.map((list)=>{
                        return(

                        <li className={`px-[25px] py-2.5 max-w-[94px] flex flex-col items-center cursor-pointer ${tabId===list.id?'bg-black':''}`} key={list.id} onClick={()=>handelTab(list.id)}>
                            {list.icon}
                            <h6 className="text-white text-center">{list.title}</h6>
                        </li>
                        )
                       }) }
                        
                    </ul>
                    <div className="bg-[#e5e9f0]">
                        {Image.map((staticimage)=>{
                            return(
                                <img src={staticimage.image} alt={staticimage.id} className={`${tabId===staticimage.id?'block':'hidden'}`} />

                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
