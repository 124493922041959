"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import {  EnjoyData } from "../constants";
import { staggerContainer } from "../utils/motion";
import { Heading } from "../components/CustomTexts";
import EnjoyCard from "../components/EnjoyCard";

const Enjoy = () => {

    return (
        <section className={`${styles.paddings}`}>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.25 }}
                className={`${styles.innerWidth} mx-auto flex flex-col`}>
                <div className="flex flex-col lg:flex-row items-center justify-between">
                    <Heading
                        title={
                            <>
                                Enjoy these features on all of
                                the above plans
                            </>
                        }
                        textStyles="text-left"
                    />
                </div>

                <div className="mt-[50px] flex lg:flex-row flex-col  gap-5">
                    {EnjoyData.map((world, index) => (
                        <EnjoyCard
                            key={world.id}
                            {...world}
                            index={index}

                        />
                    ))}
                </div>
            </motion.div>
        </section>
    );
};

export default Enjoy;
