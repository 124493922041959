"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { staggerContainer, fadeIn } from "../utils/motion";
import Button from "../components/common/Button";
import { SignUpImg } from "../assets";
import { Heading, SubHeading } from "../components/CustomTexts";
import { easyStepList } from "../constants";
import EasySteps from "../components/EasySteps";

const RealEstate = () => (
  <section className={`${styles.paddings} relative z-10 bg-white`}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto w-full flex lg:flex-row flex-col`}>
      <motion.div
        variants={fadeIn("right", "tween", 0.2, 1)}
        className={`flex-1 w-full lg:w-1/2    ${styles.flexCenter}`}>
        <img
          src={SignUpImg}
          alt="solution"
          className="w-[100%] h-[100%] object-contain"
        />
      </motion.div>
      <motion.div
        variants={fadeIn("left", "tween", 0.2, 1)}
        className="flex w-full lg:w-1/2 justify-center flex-col">
        <Heading
          title={
            <>
              Get your own <span className="text-primary">real estate</span>{" "}
              portal in three easy steps
            </>
          }
          className="text-black"
        />
        <div className="mt-[10px] flex flex-col gap-[24px]">
          <SubHeading
            title={
              <>
                {" "}
                Fully automated, with no design or development skills required.{" "}
              </>
            }
          />
          <div className="flex flex-col space-y-6 items-start justify-start">
            {easyStepList.map((item, index) => (
              <EasySteps key={item.id} {...item} index={index} />
            ))}
          </div>
        </div>
        <div className="mt-6 flex flex-row space-x-4 px-2">
          <Button text="Get Started" to="https://platform.realiti.io/" />
        </div>
      </motion.div>
    </motion.div>
  </section>
);

export default RealEstate;
