"use client";

import { motion } from "framer-motion";

import { fadeIn } from "../utils/motion";

const ExploreCard = ({ key, title, index, paragraph, icon }) => {
  return (
    <motion.div
      variants={fadeIn("right", "spring", index * 0.5, 0.75)}
      key={key}
      className={`w-full p-5 flex flex-col space-y-2 items-start  justify-start bg-white shadow-xl shadow-slate-200 rounded-20px border`}>
      <div className={`p-3 flex items-center justify-center`}>
        <img src={icon} alt={title} className="w-12 h-12" />
      </div>
      <div className="w-full text-left  items-start justify-start space-y-4 ">
        <h1 className="text-base  xl:text-[15px] font-medium text-black leading-8 ">
          {title}
        </h1>
        <p className="text-base  text-font_primary">{paragraph}</p>
        <div className="text-sm font-medium flex">
          Learn More
          <svg
            width="12"
            height="12"
            className="flex self-center ml-2"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 7L1 7M15 7L9 13M15 7L9 1"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </motion.div>
  );
};

export default ExploreCard;
