"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { fadeIn, staggerContainer } from "../utils/motion";
import { FeaturesData } from "../constants";

export const FeatureCard = ({
  key,
  id,
  title,
  index,
  paragraph,
  bg,
  text,
  icon,
}) => {
  return (
    <motion.div
      variants={fadeIn("right", "spring", index * 0.5, 0.75)}
      key={key}
      className={`w-full p-5 flex flex-col space-y-6 items-start  justify-start bg-[#121212] rounded-10px `}>
      <div className={`p-3 flex items-center justify-center`}>
        <img src={icon} alt={title} className="w-12 h-12" />
      </div>
      <div className="w-full text-left  items-start justify-start space-y-4 ">
        <h1 className="text-base w-3/4 xl:text-[20px] font-medium text-white leading-6 ">
          {title}
        </h1>
        <p className="w-3/4 text-sm  text-gray-400">{paragraph}</p>

      </div>
    </motion.div>
  );
};

const Features = () => (
  <section className={`${styles.paddings} relative z-10 bg-black p-2`}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto grid grid-cols-1 lg:grid-cols-2 gap-5`}>
      {FeaturesData.map((world, index) => (
        <FeatureCard key={world.id} {...world} index={index} />
      ))}
    </motion.div>
  </section>
);

export default Features;
