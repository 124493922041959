import React, { useState } from "react"
import { PlayIcon } from "../../assets"
import ModalVideo from 'react-modal-video';
import "react-modal-video/css/modal-video.min.css";


const PlayButton = ({ to,onClick, icon, textColor="bg-black", bgColor="bg-transparent", classNames = "", text, width = "w-auto" }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
     <ModalVideo
				channel="youtube"
				youtube={{ mute: 0, autoplay: 0 }}
        
				isOpen={isOpen}
				videoId="x1dJa6XC2tA"
				onClose={() => setOpen(false)} 
			/>
   
      <button
      className={`flex flex-row space-x-4`}
      onClick={() => setOpen(true)}
      >
       <img src={PlayIcon} alt="icon" className="w-[50px]"/>  
       <div className="text-primary text-left">
        Watch
       <div className="flex text-black items-center justify-center w-full">
       Intro Video 
       </div>
       </div>
      
      </button>
    </>
  )
}

export default PlayButton
