import React from 'react'


const Button = ({to, bgColor="bg-primary", border="rounded-md" , textColor="text-white", classNames="", text, width="w-auto"}) => {
  return (
    <>
        <a href={to} className={`px-3 py-2 flex items-center  justify-center text-sm   font-Poppins  lg:text-base ${width} ${textColor} ${bgColor} ${classNames} ${border}`}>
            {text}
        </a>
    </>
  )
}

export default Button