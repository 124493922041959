"use client";

import { motion } from "framer-motion";
import { fadeIn } from "../../utils/motion";


const ProductCard = ({ key, bg, title, index, paragraph, icon }) => {
    return (
        <motion.div
            variants={fadeIn("right", "spring", index * 0.5, 0.75)}
            key={key}
            className="bg-transparent w-[300px] flex flex-col items-center justify-center">
            <div

                className={`w-full p-5 flex flex-col space-y-2 items-center  justify-center ${bg}  rounded-20px border`}>
                <div className={`p-3 flex items-center justify-center`}>
                    <img src={icon} alt={title} className="w-20 h-20" />
                </div>
                <div className="w-full text-center  items-start justify-start space-y-4 px-3 ">
                    <h1 className="text-base  xl:text-[22px] font-bold text-black leading-8 ">
                        {title}
                    </h1>
                    <p className="text-base lg:text-lg  text-font_primary">{paragraph}</p>

                </div>
            </div>
            <div className="flex items-center justify-center text-sm text-white text-center font-medium bg-secondary w-52 lg:w-56 h-8 rounded-b-20px ">
                <p>  Read More </p>
                <div>
                    <svg
                        width="12"
                        height="12"
                        className="ml-2 text-white"
                        viewBox="0 0 16 14"
                        fill="white"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15 7L1 7M15 7L9 13M15 7L9 1"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>

            </div>
        </motion.div >

    );
};

export default ProductCard;
