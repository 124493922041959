'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import { staggerContainer } from '../utils/motion';
import { Heading, SubHeading } from '../components/CustomTexts';
import { HomeConnect } from '../assets';
import Button from '../components/common/Button';

const Integrate = () => (
  <section className={`${styles.paddings} relative z-10 flex items-center justify-center `}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} w-full flex  flex-col`}
    >
      <SubHeading
        title={<>Integrate with the tools your team is already using
        </>}
        textStyles="text-left"
      />
      <Heading title={<>Connect unit homes with every <span className="text-primary">department </span></>} textStyles="text-left mb-4" />

      <div className='flex items-center justify-center flex-col'>
        <img src={HomeConnect} alt="home-connect" className=' w-full lg:w-4/6' />
        <div className='mt-4'>
          <Button text="Revolutionise the way your team works" to="/" />
        </div>
      </div>


    </motion.div>
  </section>
);

export default Integrate;
