'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import { fadeIn, staggerContainer } from '../utils/motion';
import TableCard from '../components/TableCard';
import { Heading } from '../components/CustomTexts';

const HowWeWork = () => (
  <section className={`${styles.paddings} relative z-10 mb-5`}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto flex flex-col`}
    >

      <Heading
        title={(
          <>
            How we work
          </>
        )}
        textStyles="text-center"
      />

      <motion.div
        variants={fadeIn('up', 'tween', 0.3, 1)}
        className="relative px-2 flex-col mt-20 flex w-full items-center justify-center"
      >
        <TableCard />

      </motion.div>
    </motion.div>
  </section>
);

export default HowWeWork;
