import React from "react";


import { GettingStart } from "../../assets";
import { Link } from "react-router-dom";

const BlogCard = ({ index, title, content, tags, image, source_code_link }) => {
  return (
    <section>
      <div className="p-3 w-full">
        <div className="relative  w-full">
        <img
          src={GettingStart}
          alt="img"
          className="w-[100%] h-[100%] object-contain"
        />
          <div className="absolute inset-0 flex justify-end m-3 card-img_hover">
            <div
              onClick={() => window.open(source_code_link, "_blank")}
              className="w-10 h-10 rounded-full flex justify-center items-center cursor-pointer">
              {/* <img
                  src={github}
                  alt="source code"
                  className="w-1/2 h-1/2 object-contain"
                /> */}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="mb-2 flex flex-row space-x-3 text-gray-500 ">
            <h1 className="text-xs text-center ">Category</h1>
            <div className="text-xs text-center">. 1 Hour Ago</div>
          </div>
          <h3 className="text-black font-bold text-[18px]">{title}</h3>
          <p className="mt-2 text-gray-600 text-[14px]">{content}</p>
        </div>
        <div className="mt-3 flex flex-row space-x-4">
          <Link to="" className="text-[#1473E6] flex  flex-row text-sm font-Poppins items-center justify-center">
            Read Full{" "}
            <svg
              width="20"
              height="20"
              className="ml-2"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2_2366)">
                <path
                  d="M4.16675 10H15.8334"
                  stroke="#1473E6"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.5 13.3333L15.8333 10"
                  stroke="#1473E6"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.5 6.66675L15.8333 10.0001"
                  stroke="#1473E6"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_2366">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
        {/* <div className="mt-4 flex flex-wrap gap-2">
          {tags.map((tag) => (
            <p
              key={`${name}-${tag.name}`}
              className={`text-[14px] ${tag.color}`}>
              #{tag.name}
            </p>
          ))}
        </div> */}
      </div>
    </section>
  );
};

export default BlogCard;
