import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { motion } from "framer-motion";
import { staggerContainer } from "../utils/motion";
import styles from "../styles";
import { Footer, Navbar } from "../components";
import { BgGradient } from "../assets";
import { HashLink } from 'react-router-hash-link';



export const Sublist = [

    {
        id: 1,
        title: '1.Collection Of Information',
        slug: 'collection-of-information'
    },
    {
        id: 2,
        title: '2.Usage of Information',
        slug: 'usage-of-information'
    },
    {
        id: 3,
        title: '3.Collection of Information by Third-Party Sites and Advertisers',
        slug: 'collection-of-information-by-third-party-sites-and-advertisers'
    },
    {
        id: 4,
        title: '4.Disclosure to Third Parties',
        slug: 'disclosure-to-third-parties'
    },
    {
        id: 5,
        title: '5.Security',
        slug: 'security'
    },
    {
        id: 6,
        title: '6.Location-enabled Platform Services',
        slug: 'location-enabled-platform-services'
    },
    {
        id: 7,
        title: '7.User Discretion',
        slug: 'user-discretion'
    },
    {
        id: 8,
        title: '8.Grievances',
        slug: 'grievances'
    },
    {
        id: 9,
        title: '9.Amendments',
        slug: 'amendments'
    }
]

export const FeaturesTabItem = ({ key, title, slug, id }) => {
    return (
        <>
            <div
                key={key}
                className={`w-full flex flex-col items-start  px-2 cursor-pointer`}>
                <div className="  flex flex-col px-4 py-1">
                    <HashLink to={`/privacy-policy/#${slug}`}
                        className={` hover:text-primary text-[#2A8AFF]
            text-left mt-[6px] font-medium font-Poppins md:text-[16px]  text-[14px] `}>
                        {title}
                    </HashLink>
                </div>
            </div>
        </>
    );
};

export const MobileTap = ({ children }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="py-1.5 bg-white rounded-sm">
            <div
                className={`h-1 bg-primary`}
                style={{ width: `100%`, transition: "width 10s ease-in-out" }}></div>
            <div
                onClick={() => {
                    setOpen(!open);
                }}
                className={`px-4 py-5 flex flex-row items-center justify-between `}>
                <p className={`font-medium text-lg text-black`}>
                    Table of contents
                </p>
                <div
                    className={`${open ? "text-primary rotate-180" : "text-font_secondary rotate-0"
                        } transform transition-all ease-in duration-300`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                    </svg>
                </div>
                {/* <DropDownIcon classNames={`${currentFeature === index && open ? "text-primary rotate-180" : "text-font_secondary rotate-0"} transform transition-all ease-in duration-300`} /> */}
            </div>
            {open ? (
                <div
                    className={`transition-all duration-500 ease-in-out overflow-hidden `}>
                    <div>{children}</div>
                </div>
            ) : null}
        </div>
    );
};

const PrivacyPolicy = () => {

    const [showFloatHeader, setShowFloatHeader] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);


    useEffect(() => {
        document.getElementById("layout").addEventListener("scroll", () => {
            document.getElementById("layout").scrollTop > 50
                ? setShowFloatHeader(true)
                : setShowFloatHeader(false);
        });
    });


    return (
        <Layout
            id="layout"
            showFloatHeader={showFloatHeader}
            showSideBar={showSideBar}
            setShowSideBar={setShowSideBar}>
            <div
                style={{ backgroundImage: `url(${BgGradient})` }}
                className="bg-no-repeat ">
                <Navbar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

                <section
                    className={`${styles.yPaddings} w-full px-4 lg:px-10  flex flex-col lg:flex-row`}>
                    <div className="relative w-full lg:w-5/6  mx-auto px-2 lg:px-4 ">
                        <div className="block lg:hidden mt-6">
                            <MobileTap>
                                {Sublist.map((feature, index) => {
                                    return (
                                        <div key={index}>
                                            <FeaturesTabItem
                                                key={index}
                                                slug={feature.slug}
                                                content={feature.content}
                                                title={feature.title}
                                            />
                                        </div>
                                    );
                                })}
                            </MobileTap>
                        </div>
                        <motion.div
                            variants={staggerContainer}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: true, amount: 0.25 }}
                            className={`${styles.innerWidth} relative mx-auto pb-10 flex flex-col`}>
                            <div className="w-full mt-8 xl:mt-4 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-6">
                                    <h1 className="text-[30px] lg:text-[40px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        Privacy
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        REALITI.IO PRIVATE LIMITED (the “Company” or “Us” or “Our” or “We”) adheres strictly to information technology and data protection laws, rules and regulations. In order to ensure compliance with the same, the Company has implemented this privacy policy (the “Privacy Policy”) to set out the manner in which the Company handles the information provided by the users/registrants (“You” or “Your” or “Yourself” or “User”) of the website
                                        <a
                                            href="https://www.realiti.io/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className=" ml-2 text-[#2A8AFF] text-12px font-normal hover:font-medium">
                                            www.realiti.io
                                        </a>  and/or any software application owned and operated by the Company, collectively referred to as, “the Platform”.
                                    </p>

                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        This Privacy Policy is an electronic record under the information technology laws and is generated by a computer system and does not require any physical or digital signatures.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        This Privacy Policy also specifies the manner in which sensitive personal data or information (if any) is collected, received, stored, processed, disclosed, transferred, dealt with and handled by the Company in relation to Your use of the Platform.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        This Privacy Policy does not apply to information that You provide to, or that is collected by, any third-party through the Platform, and any third party site that You access or use in connection with using the services offered through the Platform.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        PLEASE READ THE PRIVACY POLICY CAREFULLY BEFORE USING OR REGISTERING ON THE PLATFORM OR ACCESSING ANY MATERIAL, INFORMATION OR SERVICES ON THE PLATFORM. YOUR VISITOR USE OF THE PLATFORM OR SERVICES OFFERED THROUGH THE PLATFORM SHALL SIGNIFY YOUR UNCONDITIONAL ACCEPTANCE OF THIS PRIVACY POLICY, AND YOUR AGREEMENT TO BE LEGALLY BOUND BY THE SAME.
                                    </p>
                                </div>
                            </div>


                            <div id="collection-of-information" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-6">
                                    <h3 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        1. Collection Of Information
                                    </h3>

                                    <h1 className="text-[18px] lg:text-[20px] font-Poppins tracking-normal text-black xl:leading-tight">

                                        This Privacy Policy applies to information inter-alia that:
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        You may provide to Us voluntarily while registering on Our Platform and/or using Our services (including in case of automatic registration on Our Platform, by virtue of registering on the Builder’s website or any other internet-based platform powered by the Platform).
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        This includes the information that the Company: (a) collects from You when You register on the Platform, such as your name, electronic mail address, password, phone number, bookings, chats, User records and other details; (b) collects from You related to the manner and mode of any payments made through the Platform, such as details of Your bank account, credit card, debit card, etc.; and (c) collects regarding Your content, received, uploaded or posted using the Platform.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        No liability pertaining to the authenticity/genuineness/ misrepresentation/fraud/negligence, etc. of the information disclosed shall lie on the Company nor will the Company in any way be responsible to verify any information obtained from You; and
                                        The Company may automatically receive and record when You access or use Our Platform.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        This includes the information that the Company automatically receives and records on the Company’s server logs from Your internet browser, including but not limited to, Your location, information about Your internet connection and mobile device, Internet Protocol address, cookie information, web beacon information and details of the web pages You visit.
                                    </p>div
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        Further, the Company may collect information about Your preferences and settings such as time zone and language, Your searches and the results You selected from the search and Your transactional information.All information gathered by the Company shall be stored on servers, log files and any other storage systems owned by the Company or by third parties. Additionally, information may be stored on the Company’s computers, hard drives, hard copies, etc…
                                    </p>

                                </div>
                            </div>

                            <div id="usage-of-information" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        2. Usage of Information
                                    </h1>

                                    <h1 className="w-full lg:w-4/5 text-[18px] lg:text-[20px] font-Poppins tracking-normal text-black xl:leading-tight">

                                        The information gathered by the Company as specified in Clause 1, may be used for the following purposes:
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        1.to provide, maintain, develop, protect and improve the services provided through the Platform, manage Your account, etc.;
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        2.to understand Your preferences and to enhance and customize Your experience of using the Platform;
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        3.to respond to Your comments, reviews, and questions and provide better service;

                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        4.to carry out the Company’s obligations and enforce the Company’s rights arising from any contracts entered into between You and the Company, including for billing and collection of amounts;
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        5.to communicate important notices or changes in the services provided by the Company, use of the Platform and the documents/policies which govern the relationship between You and the Company;
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        6.for internal purposes such as auditing, data analysis and research relating to the Platform or services provided through the Platform;
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        7.for promotion and marketing purposes;
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        8.for sharing such information with any third party, including any User on the Platform and any group companies of the Company, in the course of providing the services through the Platform; and
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        9.for any other purpose with Your consent.
                                    </p>

                                </div>
                            </div>

                            <div id="collection-of-information-by-third-party-sites-and-advertisers" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        3. Collection of Information by Third-Party Sites and Advertisers
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        You hereby acknowledge and agree that when You use the Platform, there may be certain links which may direct You to other websites or applications not operated/maintained by the Company (the “Third Party Site”). The manner in which Your information is collected, received, stored, processed, disclosed, transferred, dealt with and handled by such Third Party Sites is governed by the terms and conditions and privacy policy of the respective Third Party Site(s).
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        The Company urges You to acquaint yourself with the terms and conditions and privacy policy of every such Third Party Site. The Company hereby expressly disclaims all liabilities with respect to the manner in which the Third Party Site collects and/or uses Your information.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        On Our Platform, there may be third parties who advertise their products and/or services. Such third parties may place or recognize a unique “cookie” on Your browser and may use information about Your visits to Our Platform and other websites in order to provide advertisements about goods and services of interest to You.

                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        We shall, in no event, be liable for any unauthorized or unlawful disclosures of Your personal information made by advertisers, who are not subject to Our control
                                    </p>
                                </div>
                            </div>

                            <div id="disclosure-to-third-parties" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        4 .Disclosure to Third Parties                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        The Company may need to disclose Your information, if required to do so by law and if such action is necessary inter-alia to (a) comply with a legal obligation; (b) protect and defend the rights or property of the Company, including to enforce Our agreements, policies, and Terms of Use; (c) protect the personal safety of the Company, the Users, or any person, in an emergency; and (d) protect the Company from incurring any legal liability. In such an event the Company shall be under no obligation to inform You or seek Your approval or consent.


                                    </p>

                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        The Company shall be at liberty to disclose Your information to: (i) any third party to facilitate the provision of services through the Platform; and (ii) third parties who provide services, such as auditing, data collection, reporting, site analytics, and assistance with delivery of relevant marketing messages and advertisements. If You provide a mobile phone number and/or e-mail address, the Company or the third party service providers or the persons authorized by them may send You text messages/e-mails in relation to Your use of the Platform. The Company contractually requires these third parties to keep such information confidential and use it only for the purposes for which the Company discloses it to them. These third parties may view, edit, or set their own cookies or may place beacons on Your personal information. The use of these technologies by these third parties is subject to their own privacy policies and is not covered by this Privacy Policy.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        We may also share aggregated and de-identified information with third parties in Our discretion.

                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        We may share personal information with third-party vendors, consultants, and other service providers who work for the Company, who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which We disclose it to them.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        The Company may disclose or transfer Your information (personal or otherwise) to any subsidiary or affiliate, and to a third party if the Company sells, transfers or divests all or a portion of the Company’s business or assets to another company in connection with or during negotiation of any merger, financing, acquisition, bankruptcy, dissolution, transaction, or proceeding. Such third parties shall be contractually bound to not disclose further, any personal information disclosed to them.
                                    </p>
                                </div>
                            </div>

                            <div id="security" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        5. Security
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">

                                        The Company shall take reasonable steps to help protect Your rights of privacy and Your information (personal or otherwise) in an effort to prevent loss, misuse, unauthorized access, disclosure, alteration, or destruction of such information, in compliance with the applicable laws. When payment card account information is being transmitted on or through the Platform, it will be protected by encryption technology.
                                    </p>

                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        You expressly consent to the sharing of Your information with third party service providers, including payment gateways, to process payments and manage payment card information. The Company does not itself store Your payment card account information and does not have direct control over or responsibility for Your payment card account information.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        Hence, the Company cannot guarantee that transmissions of Your payment card account information or personal information will always be secure or that unauthorized third parties will never be able to defeat the security measures taken by the Company or the Company’s third-party service providers.

                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        The Company assumes no liability or responsibility for disclosure of Your information due to errors in transmission, unauthorized third-party access, or other causes beyond its control. You play an important role in keeping Your personal information secure. You shall not share Your username, password, or other security information for Your account with anyone.
                                    </p>

                                </div>
                            </div>

                            <div id="location-enabled-platform-services" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        6. Location-enabled Platform Services                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        When You use any of the Company’s location-enabled services, the Company may collect and process information about Your computer’s/device’s GPS location and such related information to customize the services available through the Platform with location-based features.
                                    </p>

                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        The Company may associate location data with Your other information. You can withdraw Your consent at any time by disabling the location-tracking functions on Your computer/ device. However, this may affect Your enjoyment and use of certain functionalities on the Platform.
                                    </p>
                                </div>
                            </div>
                            <div id="user-discretion" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        7. User Discretion
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        You may opt out of receiving promotional emails from the Company by following the instructions in those emails. If You opt out, the Company may still send You non-promotional emails, such as emails about Your account.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        Cookie choices. Most web browsers are set to accept cookies by default. If You prefer, You can usually choose to set Your browser to remove or reject cookies. If You choose to remove or reject cookies, this could affect certain features or services on the Platform.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        Should You desire to opt out of disclosing Your information to the Company, the Company requests You to close Your User account and stop accessing the Platform and the services on the Platform. Thereafter, the Company may delete Your information (personal or otherwise) or de-identify it so that it is anonymous and not attributable to You.
                                    </p>
                                </div>
                            </div>

                            <div id="grievances" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        8. Grievances
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        In the event You have any grievances relating to the Privacy Policy, please inform the Company within 24 hours of the occurrence of the instance from which the grievance has arisen, by writing your feedback to the Grievance Officer by filling Support form.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        The Company shall respond to all grievances lodged with the Company, within 72 (seventy-two) hours through emails, telephone calls, personal visits, etc.as it deems fit and shall redress Your grievance expeditiously.
                                    </p>

                                </div>
                            </div>

                            <div id="amendments" className="w-full mt-8 xl:mt-14 mx-auto flex items-start justify-start ">
                                <div className="flex flex-col items-start space-y-4">
                                    <h1 className="text-[30px] lg:text-[30px] font-Poppins tracking-normal text-black xl:leading-tight">
                                        9. Amendments
                                    </h1>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        The Company retains an unconditional right to modify or amend this Privacy Policy without any requirement to notify You of the same. You can determine when this Privacy Policy was last modified by referring to the “Last Updated” legend above.
                                    </p>
                                    <p className="w-full lg:w-4/5 text-sm md:text-lg font-Poppins tracking-normal text-gray-600 leading-6 xl:leading-7">
                                        It shall be Your responsibility to check this Privacy Policy periodically for changes. Your continued use of the Platform following the posting of changes to this Privacy Policy on the Platform will constitute Your consent and acceptance of those changes.
                                    </p>

                                </div>
                            </div>

                        </motion.div>

                    </div>
                    <div className="w-full lg:w-1/5">
                        <div className="hidden w-full shadow-sm bg-white border pb-4 rounded-md space-x-2 lg:flex flex-col  overflow-x-hidden overflow-y-hidden">
                            <p className={`font-medium mt-8 mb-3 px-6 text-base text-black`}>
                                Table of contents
                            </p>
                            {Sublist.map((feature, index) => {
                                return (
                                    <div key={index}>
                                        <FeaturesTabItem
                                            key={index}
                                            slug={feature.slug}
                                            content={feature.content}
                                            // icon={feature.icon}
                                            title={feature.title}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </div>

            <div className="relative"></div>
            <Footer />
        </Layout>
    );
};

export default PrivacyPolicy;
