import React  from 'react'
import Sidebar from './common/Sidebar'
import FloatHeader from './FloatHeader'


const Layout = ({children, id , showSideBar, setShowSideBar, showFloatHeader}) => {
  return (
    <div id={id} className={`w-full h-screen mx-auto`}>

      {/* NavBar */}
      <FloatHeader showFloatHeader={showFloatHeader} showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>
 

      {/* SideBar */}
      {showSideBar && <div className="absolute w-screen h-screen bg-opacity-60" style={{zIndex:1000}}></div>}
      <Sidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} /> 

      <main className='w-full h-screen'>
          {children}
      </main>

      {/* <Footer /> */}
    </div>
  )
}

export default Layout