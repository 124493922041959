"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { CategoriesData } from "../constants";
import { staggerContainer } from "../utils/motion";


const Rounded = ({ title, subtitle, icon }) => (
  <div className={`flex flex-col items-center justify-center space-y-5`}>
    <div
      className={`flex items-center justify-center w-36 h-36 rounded-full bg-white shadow-md`}
    >
      <img src={icon} alt="icon" className="px-3 py-3 w-24"/>
    </div>
    <div>
      <p className="font-medium text-[18px] text-black leading-[32.4px]">
        {title}
      </p>

    </div>
  </div>
);

const Categories = () => {

  return (
    <section className={`${styles.paddings}`}>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`${styles.innerWidth} flex flex-col`}>


        <div className="mt-[10px] flex lg:flex-row flex-col items-center justify-center  gap-5 lg:gap-16">
          {CategoriesData.map((world, index) => (
            <Rounded
              key={world.id}
              {...world}
              index={index}

            />
          ))}
        </div>
      </motion.div>

    </section>
  );
};

export default Categories;
