"use client";

import { motion } from "framer-motion";

import styles from "../styles";
import { fadeIn, staggerContainer } from "../utils/motion";
import { CuriousData } from "../constants";
import { Line } from "../assets";
import { Heading } from "../components/CustomTexts";

export const CuriousCard = ({
  key,
  id,
  title,
  index,
  paragraph,
  bg,
  text,
  icon,
}) => {
  return (
    <>
      <motion.div
        variants={fadeIn("right", "spring", index * 0.5, 0.75)}
        key={key}
        className={`pb-8 w-full flex flex-col space-y-2 text-center items-center  justify-center  `}>
        <div
          className={`px-5 py-4 flex  font-bold text-xl text-center text-white  bg-[#121212] rounded-10px`}>
          {id}
        </div>
        <div className="w-full flex flex-col items-center  justify-center  text-center space-y-3 ">
          <h1 className="w-3/5 text-center text-lg  xl:text-[18px] font-medium text-black leading-6 ">
            {title}
          </h1>
          <p className="w-2/3 lg:w-4/5 text-sm  text-font_primary">
            {paragraph}
          </p>
        </div>
      </motion.div>
    </>
  );
};

const Curious = () => (
  <section className={`${styles.paddings} relative z-10 `}>
    <div className="flex flex-col lg:flex-row items-center justify-center pb-24">
      <Heading
        title={<>Curious why the world’s leading <br /> builders choose Realiti?</>}
        textStyles="text-center"
      />
    </div>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.innerWidth} px-10 flex mt-6 flex-col lg:flex-row items-center justify-center`}>

      {CuriousData.map((world, index) => (
        <>
          <CuriousCard key={world.id} {...world} index={index} />
          <img
            src={Line}
            alt="line"
            className={`${world.id === 4 ? "hidden" : "hidden lg:block"}  `}
          />
        </>
      ))}
    </motion.div>
  </section>
);

export default Curious;
