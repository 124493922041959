'use client';

import { motion } from 'framer-motion';

import styles from '../styles';
import { ExperiencedData } from '../constants';
import { fadeIn, staggerContainer } from '../utils/motion';

export const Expirence = ({ key, id, url, number, index, exp, bg, text, icon }) => {

    return (


        <motion.div
            variants={fadeIn('left', 'spring', index * 0.5, 1)}
            key={key}
            className={`w-full p-3 flex flex-col space-y-2 items-center  justify-center  `}
        >
            <div className="w-full text-left  items-start justify-start space-y-2 ">
                <h1 className="text-base  xl:text-[25px] font-medium text-black leading-8 ">
                    {number}
                </h1>
                <p className="text-base  text-font_primary">{exp}</p>
            </div>
        </motion.div>
    )
}



const Experienced = () => {

    return (
        <section className={`${styles.paddings}`} >
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.25 }}
                className={`${styles.innerWidth} mx-auto flex flex-col`}
            >
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 items-center justify-center">
                    {ExperiencedData.map((logos, index) => (
                        <Expirence
                            key={index}
                            {...logos}
                            index={index}
                        />
                    ))}
                </div>
            </motion.div>
        </section>
    );
};

export default Experienced;
