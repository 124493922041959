
const EasySteps = ({ title,subtitle, imgUrl }) => (
  <div className={`flex flex-row items-center justify-center`}>
    <div
      className={`flex items-center justify-center px-4 py-4 rounded-full bg-secondary`}
    >
    <img src={imgUrl} alt="icon" />

    </div>
    <div>
    <p className="flex-1 ml-[30px] font-normal text-[18px] text-black leading-[32.4px]">
      {title}
    </p>
    <p className="flex-1 ml-[30px] font-normal text-[15px] text-font_primary">
      {subtitle}
    </p>
    </div>
    
  </div>
);

export default EasySteps;
