import React, { useRef } from "react";
import PropTypes from "prop-types";
import InnerHTML from "html-react-parser";
// import '../../styles/styles.scss'
// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";

import { Swiper, SwiperSlide,  } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { QuoteImg, TestimonialImg } from "../../assets";

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

const SwiperSlider = (props) => {
  const swiperRef = useRef();

  const sliderSettings = {
    440: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
    680: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
  };

  return (
    <>
      <Swiper
        breakpoints={sliderSettings}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        spaceBetween={props.spaceBetween}
        loop={true}
        autoplay={{ delay: props.slideDeley }}
        slidesPerView={props.slidesPerView}
        pagination={false}
        scrollbar={false}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log('slide change')}
        navigation={{
          nextEl: ".review-swiper-button-next",
          prevEl: ".review-swiper-button-prev",
        }}>
        {props.slides.map((slide, i) => (
          <SwiperSlide className="p-4" key={i}>
            <div
              style={{ backgroundImage: `url(${TestimonialImg})` }}
              className="bg-no-repeat bg-cover flex flex-row bg-white shadow-lg border p-10 lg:p-16 rounded-30px">
              <div className="flex flex-col">
                <div className="flex items-center justify-end ">
                  <img
                    className="absolute right-0 p-20 mt-4 w-[38%]"
                    src={QuoteImg}
                    alt="Workplace"
                  />
                </div>
                {slide.content && InnerHTML(slide.content)}
                {slide.cite && InnerHTML(slide.cite)}
                <div className=" space-x-3 flex items-end justify-end">
                  <button
                    className="bg-white rounded-full p-2"
                    onClick={() => swiperRef.current?.slidePrev()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                      />
                    </svg>
                  </button>
                  <button
                    className="bg-white rounded-full p-2"
                    onClick={() => swiperRef.current?.slideNext()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

SwiperSlider.propTypes = {
  slides: PropTypes.array,
};

export default SwiperSlider;
